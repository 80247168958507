import moment from "moment";

const promotionCodesConf = {
  title: "Your Promotion Codes",
  nameSpace: "ypromocodes",
  endPoint: "/promotions",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&orderBy=expire_date desc",
  queryLimitTotal:
    "&createFrom=" + moment().add(-30, "days").format("YYYY-MM-DD"),
  tableCfg: {
    mainKey: "couponCode",
    noDataInfo: "You currently have no promotion codes.",
    columns: [
      { title: "Coupon Code", key: "couponCode" },
      //   {
      //     title: "Create Date",
      //     key: "createDate",
      //     type: "localTime",
      //     format: "DD/MM/yyyy",
      //   },
      {
        title: "Valid For",
        key: "products",
        type: "catsFromProducts",
        alias: true,
      },
      {
        title: "Expiry Date",
        key: "expireDate",
        type: "validTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Discount",
        key: "discountAmount",
        prefix: "$",
      },
      {
        title: "Applied",
        key: "usedQty",
        type: "yesNo",
      },
      {
        title: "Reusable",
        key: "repeatQty",
        type: "yesNo_2",
      },
      {
        title: " ",
        type: "delete",
        limitKey: "usedQty",
        delName: "Coupon Code",
        allowed: [0],
        repOnly: true,
      },
      {
        title: " ",
        type: "doc",
        urlKey: "info2",
      },
    ],
    btns: [
      {
        text: "Create New",
        type: "link",
        path: "/promotioncodes/createnew",
        totalLimitKey: "fax",
        repOnly: true,
      },
    ],
  },
  maxPage: 10,
  pageSize: 10,
  pageSizeOpt: true,
};

export default promotionCodesConf;
