import moment from "moment";

const historyOrdersConf = {
  title: "Order History",
  nameSpace: "hisor",
  endPoint: "/saleorders",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query:
    "&deliveryFlag=3,9&qryFrom=" +
    moment().add(-730, "days").format("YYYY-MM-DD") +
    "&orderBy=edd desc",
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "There is no history order yet.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No.", key: "info2" },
      {
        title: "Completed Date",
        key: "confirmDate",
        type: "localTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Status",
        type: "completed",
        key: "status",
        subKey: "deliveryFlag",
        textAlign: "right",
      },
      {
        title: " ",
        type: "doc",
        limitKey: "status",
        allowed: [1, 2, 10, 11, 12],
        limitKey2: "saleOrderName",
        urlKey: "orderSummaryUrl",
      },
    ],
  },
  maxPage: 99,
  pageSize: 10,
  pageSizeOpt: true,
};

export default historyOrdersConf;
