import React, { useCallback, useEffect, useState } from "react";
import TitleCard from "./title-card";
import { OrderForm } from "./draft-order";
import {
  FullWidthContainer,
  SpanBGeneral,
  InputGeneral,
  theme,
  TableGeneral,
  H5Title,
  StyledCheckbox,
  DivDecorRow,
  SelectGeneral,
  PGeneral,
  SpanLGeneral,
  ButtonFuncG,
  ButtonFuncR,
} from "../../configurations/general-conf";
import {
  ButtonFuncGApply,
  ThFirst,
  ThDraftOrder,
  TdDraftOrder,
  FlexCheckout,
  SpInsTextarea,
  NumberInput,
} from "./draft-order";
import styled from "styled-components";
import store from "../../store";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import SimpleConfirmation from "./simple-confirmation";

const InputGeneralReturn = styled(InputGeneral)`
  height: ${theme.proportion * 28 * theme.mobileResizeFactor}px;
`;

const ButtonFuncGReturn = styled(ButtonFuncGApply)`
  height: ${theme.proportion * 28 * theme.mobileResizeFactor}px;
`;

const ThFirstReturn = styled(ThFirst)`
  line-height: ${theme.proportion * 15 * theme.mobileResizeFactor}px;
  border-bottom: none;
`;

const ThDraftOrderReturn = styled(ThDraftOrder)`
  line-height: ${theme.proportion * 15 * theme.mobileResizeFactor}px;
  border-bottom: none;
`;

const SpInsTextareaReturn = styled(SpInsTextarea)`
  margin-top: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
`;

const PGeneralReturn = styled(PGeneral)`
  font-style: italic;
`;

const TermsSection = styled.ul`
  list-style: disc;
  margin-left: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  font-size: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  font-family: ${theme.midFont};
  color: ${theme.darkerBiege};

  & li {
    margin-top: ${theme.proportion * 8 * theme.mobileResizeFactor}px;
  }
`;

const TrReturn = styled.tr`
  color: ${(props) => (props.editable ? theme.charcoal : theme.darkerBiege)};
`;

const ReturnOrder = (props) => {
  const loadReturnOrder = useCallback(
    async (uuid) => {
      // console.log(uuid);
      const res = await axios.get("/returnorders/" + uuid);
      // console.log(res.data);
      if (res.data?.objs?.length > 0) {
        const returnOrder = res.data.objs[0];
        const res_order = await axios.get(
          "/saleorders/saleOrderName/" + returnOrder.invoiceNum
        );
        const saleOrder = res_order.data?.objs?.[0];
        setReturnOrder({
          ...returnOrder,
          returnItems: saleOrder.saleItems.map((item) => {
            return {
              ...item,
              returnItemUuid:
                returnOrder.returnItems.find(
                  (obj) => obj.barcodes === item.barcodes
                )?.returnItemUuid || null,
              quantityToReturn:
                returnOrder.returnItems.find(
                  (obj) => obj.barcodes === item.barcodes
                )?.quantity || 0,
            };
          }),
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <SimpleConfirmation
                title="Oops..."
                message={res.data.msg}
                onClose={onClose}
              />
            );
          },
        });
        props.history.push("/returns");
      }
    },
    [props.history]
  );

  useEffect(() => {
    const { uuid } = props.match.params;
    uuid && loadReturnOrder(uuid);
  }, [props.match.params, loadReturnOrder]);

  const [dissub, setDissub] = useState(false);
  const [returnOrder, setReturnOrder] = useState({
    customerUuid: store.getState()["company"]["customerUuid"],
    // returnOrderName: null,
    invoiceNum: "",
    packingStatus: 0,
    returnReason: null,
    notes: null,
    saleOrderUuid: null,
    status: 0,
    returnItems: [],
  });

  const returnOrderConf = {
    title: "Returns Form",
    topBtns: [
      {
        text: "Go back to Returns Dashboard",
        type: "link",
        path: "/returns",
      },
    ],
  };

  const toPath = (e, path) => {
    e.preventDefault();
    props.history.push(path);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(returnOrder);
    const returnOrderPost = returnOrder.returnOrderUuid
      ? {
          ...returnOrder,
          returnItems: returnOrder.returnItems.map((item) => {
            return {
              ...item,
              quantity: item.quantityToReturn,
            };
          }),
        }
      : {
          ...returnOrder,
          returnItems: returnOrder.returnItems
            .filter((item) => {
              return item.quantityToReturn > 0;
            })
            .map((item) => {
              return {
                ...item,
                quantity: item.quantityToReturn,
              };
            }),
        };
    // console.log(returnOrderPost);

    // sum of quantity to return
    const sumQty = returnOrderPost.returnItems.reduce((acc, item) => {item.quantityToReturn && (acc += item.quantityToReturn); return acc;}, 0);
    // console.log(sumQty);

    if ((returnOrderPost.returnItems.length === 0 || !sumQty) && returnOrder.status === 1) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SimpleConfirmation
              title="No Items Selected"
              message="Dear customer, please select at least one item to return."
              onClose={onClose}
            />
          );
        },
      });
      return;
    }

    setDissub(true);
    const res = returnOrder.returnOrderUuid
      ? await axios.put(
          "/returnorders/" + returnOrder.returnOrderUuid + "/edit",
          returnOrderPost
        )
      : await axios.post("/returnorders/new", returnOrderPost);
    // console.log(res.data);
    if (res.data.result === 1) {

      if(returnOrderPost.placeDate){
        const returnOrderRePost = {...returnOrderPost, status: 1};
        const resRePost = await axios.put("/returnorders/" + returnOrder.returnOrderUuid + "/edit", returnOrderRePost);
        if(resRePost.data.result === 1){
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <SimpleConfirmation
                  title="Return Order Updated"
                  message="Your return order has been updated."
                  onClose={onClose}
                />
              );
            },
          });
          props.history.push("/returns");
        }else{
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <SimpleConfirmation
                  title="Oops..."
                  message={resRePost.data.msg}
                  onClose={onClose}
                />
              );
            },
          });
          setDissub(false);
        }
        return;
      }

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SimpleConfirmation
              title={
                returnOrder.status === 0
                  ? "Information Saved"
                  : "Return Order Placed"
              }
              message={
                returnOrder.status === 0
                  ? "Your return order information has been saved as a draft."
                  : "Dear customer, your return request has been received. An email containing your return number and conditions has been sent to your registered email. You can also access this information in your B2B returns list."
              }
              onClose={onClose}
            />
          );
        },
      });
      props.history.push("/returns");
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SimpleConfirmation
              title="Oops..."
              message={res.data.msg}
              onClose={onClose}
            />
          );
        },
      });
      setDissub(false);
    }
  };

  const loadOrder = async (invoiceNum) => {
    // console.log(returnOrder);
    setDissub(true);
    const res = await axios.get("/saleorders/saleOrderName/" + invoiceNum);
    // console.log(res.data);
    if (res.data?.objs?.length > 0) {
      setReturnOrder({
        ...returnOrder,
        invoiceNum,
        // returnOrderName:
        //   "RO" + invoiceNum + "-" + Math.floor(Math.random() * 10000),
        saleOrderUuid: res.data.objs[0].saleOrderUuid,
        returnItems: res.data.objs[0].saleItems.map((item) => {
          return {
            ...item,
            quantityToReturn: 0,
          };
        }),
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SimpleConfirmation
              title="Invalid Invoice Number"
              message={
                "Dear customer, the Invoice No. #" +
                invoiceNum +
                " is not eligible for return. Please refer to our return policy for further clarification."
              }
              onClose={onClose}
            />
          );
        },
      });
    }
    setDissub(false);
  };

  const handleNumberChange = (barcodes, quantityToReturn) => {
    setReturnOrder({
      ...returnOrder,
      returnItems: returnOrder.returnItems.map((item) => {
        return item.barcodes === barcodes
          ? {
              ...item,
              quantityToReturn: Number(quantityToReturn),
              updateFlag: item.returnItemUuid
                ? Number(quantityToReturn) === 0
                  ? -1
                  : 2
                : 1,
            }
          : item;
      }),
    });
  };

  const handleConfirmClear = (e) => {
    if (returnOrder.saleOrderUuid !== null) {
      setReturnOrder({
        ...returnOrder,
        // returnOrderName: null,
        invoiceNum: "",
        saleOrderUuid: null,
        returnItems: [],
      });
    } else {
      const invoiceNum = returnOrder.invoiceNum.replace(/^0+(?=\d)/, "");
      setReturnOrder({
        ...returnOrder,
        invoiceNum,
      });
      e.preventDefault();
      invoiceNum.length > 4 && loadOrder(invoiceNum);
    }
  };

  // console.log(returnOrder);

  return (
    <TitleCard {...returnOrderConf} toPath={toPath} contentPadding="25">
      <OrderForm onSubmit={handleSubmit}>
        <FullWidthContainer>
          <SpanBGeneral xfontSize="12" xmarginRight="10">
            Invoice No.
          </SpanBGeneral>
          <InputGeneralReturn
            type="text"
            id="invoice_no"
            name="invoice_no"
            xwidth="210"
            maxLength="20"
            required
            value={returnOrder.invoiceNum}
            disabled={returnOrder.saleOrderUuid !== null}
            onChange={(e) =>
              setReturnOrder({ ...returnOrder, invoiceNum: e.target.value })
            }
          />
          {!returnOrder.returnOrderUuid && (
            <ButtonFuncGReturn
              disabled={dissub || returnOrder.invoiceNum.length < 5}
              onClick={(e) => handleConfirmClear(e)}
            >
              {returnOrder.saleOrderUuid === null ? "Confirm" : "Clear"}
            </ButtonFuncGReturn>
          )}

          <TableGeneral xmarginTop="10">
            <thead>
              <tr>
                <ThFirstReturn>Product/s</ThFirstReturn>
                <ThDraftOrderReturn>
                  Purchased
                  <br />
                  Unit/s
                </ThDraftOrderReturn>
                <ThDraftOrderReturn>
                  Unit/s to be
                  <br />
                  Returned
                </ThDraftOrderReturn>
                <ThDraftOrderReturn>
                  Quantity to
                  <br />
                  be Returned
                </ThDraftOrderReturn>
              </tr>
            </thead>
            <tbody>
              {returnOrder.returnItems.length > 0 ? (
                returnOrder.returnItems.map((item, index) => {
                  return (
                    <TrReturn key={index} editable={item.info1 ? false : true}>
                      <TdDraftOrder>
                        {item.product?.productName ||
                          item.product?.productColor}
                        <SpanLGeneral
                          xmarginLeft="14"
                          title="Allocated Batch Number"
                        >
                          {item.barcodes}
                        </SpanLGeneral>
                      </TdDraftOrder>
                      <TdDraftOrder>
                        {item.quantity}{" "}
                        {item.product?.unitName?.split("|")[1] || "Unit/s"}
                      </TdDraftOrder>
                      <TdDraftOrder>
                        <NumberInput
                          type="number"
                          step="1"
                          min="0"
                          max={item.quantity}
                          id={`rQty_${item["barcodes"]}`}
                          name={`rQty_${item["barcodes"]}`}
                          key={`rQty_${item["barcodes"]}_${item.quantityToReturn}`}
                          defaultValue={item.quantityToReturn}
                          disabled={item.info1}
                          xwidth="70"
                          onChange={(e) => e.target.setCustomValidity("")}
                          onBlur={(e) => {
                            if (
                              e.target.value > item.quantity ||
                              e.target.value < 0
                            ) {
                              e.target.setCustomValidity(
                                "Dear customer, your requested return quantity exceeds the purchased volume listed on the invoice. Please review and adjust accordingly."
                              );
                            } else {
                              e.target.setCustomValidity("");
                              handleNumberChange(
                                item["barcodes"],
                                e.target.value
                              );
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              e.target.blur();
                            }
                          }}
                        />{" "}
                        {item.product?.unitName?.split("|")[1] || "Unit/s"}
                      </TdDraftOrder>
                      <TdDraftOrder>
                        {parseFloat(
                          (
                            item.quantityToReturn * (item.product.unitSize || 1)
                          ).toFixed(
                            item.product.unitSize?.toString().split(".")[1]
                              ?.length || 0
                          )
                        )}{" "}
                        {item.product?.unitSymbol || "--"}
                      </TdDraftOrder>
                    </TrReturn>
                  );
                })
              ) : (
                <tr>
                  <TdDraftOrder>--</TdDraftOrder>
                  <TdDraftOrder>--</TdDraftOrder>
                  <TdDraftOrder>--</TdDraftOrder>
                  <TdDraftOrder>--</TdDraftOrder>
                </tr>
              )}
            </tbody>
          </TableGeneral>

          <H5Title xfontSize="12" xmarginTop="40">
            Packing Status:
          </H5Title>
          <FlexCheckout xpaddingTop="25">
            <FlexCheckout>
              <SpanBGeneral xfontSize="12" xmarginRight="10">
                On Pallet
              </SpanBGeneral>
              <StyledCheckbox>
                <input
                  type="checkbox"
                  id="ps_on_pallet"
                  name="ps_on_pallet"
                  key={`ps_on_pallet_${returnOrder.packingStatus}`}
                  defaultChecked={returnOrder.packingStatus === 0}
                  disabled={returnOrder.packingStatus === 0}
                  onChange={() =>
                    setReturnOrder({ ...returnOrder, packingStatus: 0 })
                  }
                />
                <span></span>
              </StyledCheckbox>
            </FlexCheckout>
            <FlexCheckout xmarginLeft="40">
              <SpanBGeneral xfontSize="12" xmarginRight="10">
                Loose Packs
              </SpanBGeneral>
              <StyledCheckbox>
                <input
                  type="checkbox"
                  id="ps_loose_packs"
                  name="ps_loose_packs"
                  key={`ps_loose_packs_${returnOrder.packingStatus}`}
                  defaultChecked={returnOrder.packingStatus === 1}
                  disabled={returnOrder.packingStatus === 1}
                  onChange={() =>
                    setReturnOrder({ ...returnOrder, packingStatus: 1 })
                  }
                />
                <span></span>
              </StyledCheckbox>
            </FlexCheckout>
          </FlexCheckout>
          <DivDecorRow xheight="40" />

          <H5Title xfontSize="16" xmarginTop="40" xmarginBottom="30">
            Reason for Return
          </H5Title>
          <SpanBGeneral xfontSize="12" xmarginRight="10">
            Reason
          </SpanBGeneral>
          <SelectGeneral
            id="reason_return"
            name="reason_return"
            key={`reason_return_${returnOrder.returnReason}`}
            xheight="28"
            xwidth="200"
            defaultValue={returnOrder.returnReason}
            required
            onChange={(e) =>
              setReturnOrder({
                ...returnOrder,
                returnReason:
                  e.target.value === "" ? null : Number(e.target.value),
                notes: Number(e.target.value) !== 2 ? null : returnOrder.notes,
              })
            }
          >
            <option value="">Please Select</option>
            <option value="0">Change of Mind</option>
            <option value="1">Excess Stock</option>
            <option value="2">Other</option>
          </SelectGeneral>
          <SpInsTextareaReturn
            id="other_reason"
            name="other_reason"
            rows="9"
            key={`other_reason_${returnOrder.notes}`}
            defaultValue={returnOrder.notes}
            onBlur={(e) =>
              setReturnOrder({ ...returnOrder, notes: e.target.value })
            }
            disabled={returnOrder.returnReason !== 2}
            required={returnOrder.returnReason === 2}
            placeholder="If you have selected ‘Other’ please specify the reason."
          />
          <PGeneralReturn xfontSize="12" xmarginTop="10">
            <SpanBGeneral>IMPORTANT: </SpanBGeneral>
            <SpanLGeneral>
              All return stock MUST be returned to the AST designated warehouse within 21
              days from the return confirmation.
              <br />
              Should the stock not be returned within this time frame, the
              return request will be cancelled.
            </SpanLGeneral>
          </PGeneralReturn>
          <PGeneral xfontSize="10" xmarginTop="40" xcolor="darkerBiege">
            All stock returns are subject to the following conditions and will
            not be accepted unless a confirmation and a return authorization
            number is given by our office:
          </PGeneral>
          <TermsSection>
            <li>
              Return Period: A Returns Form must be issued within 30 days of
              purchase.
            </li>
            <li>
              Return Approval: All returns stock MUST be returned to the AST
              designated warehouse within 21 days from the return confirmation. Should the stock
              not be returned within this time frame,
              <br />
              the return request will be cancelled.
            </li>
            <li>
              Condition of Product: To be eligible for a return, the item must
              be unused and in a full re-sellable condition (i.e. Unopened and
              undamaged).
            </li>
            <li>
              Return Shipping: All freight for stock returns are to be organized
              by the customer and delivered back to AST warehouse. Any freight
              costs to return stock are at the customers own expense
            </li>
            <li>
              Restocking Fee: $100.00 restock fee will be applied per return
              order.
            </li>
            <li>
              Non-returnable items: Discontinued lines and clearance stocks are
              not available for return.
            </li>
          </TermsSection>
          <FlexCheckout xpaddingTop="40" xpaddingBottom="40">
            <SpanBGeneral xfontSize="12" xmarginRight="10">
              I have read and understood the above Terms & Conditions
            </SpanBGeneral>
            <StyledCheckbox>
              <input
                type="checkbox"
                id="terms_check"
                name="terms_check"
                key={`terms_check_${returnOrder.placeDate}`}
                required={returnOrder.status === 1}
                defaultChecked={returnOrder.placeDate ? true : false}
              />
              <span></span>
            </StyledCheckbox>
          </FlexCheckout>
          <ButtonFuncR
            xmarginRight="10"
            xwidth="140"
            type="submit"
            onClick={() => setReturnOrder({ ...returnOrder, status: 0 })}
          >
            Save Information
          </ButtonFuncR>
          {!returnOrder.placeDate && <ButtonFuncG
            xwidth="140"
            type="submit"
            onClick={() => setReturnOrder({ ...returnOrder, status: 1 })}
          >
            Place Return
          </ButtonFuncG>}
        </FullWidthContainer>
      </OrderForm>
    </TitleCard>
  );
};

export default ReturnOrder;
