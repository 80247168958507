import axios from "axios";
import store from "../store";

const getDataTable = async (nameSpace, pageIndex) => {
  const { pageSize, currentPage, endPoint, query, qryStr, queryLimitTotal } =
    store.getState()[nameSpace];
  const toPage = pageIndex ? pageIndex : currentPage;
  const pageStr =
    "&_pageStart=" + (toPage - 1) * pageSize + "&_pageSize=" + pageSize;
  const restUrl =
    endPoint +
    (endPoint.indexOf("?") > -1 ? "" : "?_time=0") +
    pageStr +
    query +
    (qryStr ? "&qryStr=" + qryStr : "");

  let limitTotal = 0;
  if (queryLimitTotal) {
    const restUrlLimitTotoal =
      endPoint +
      (endPoint.indexOf("?") > -1 ? "" : "?_time=0") +
      queryLimitTotal;
    // console.log(restUrlLimitTotoal);
    const resLimitTotal = await axios.get(restUrlLimitTotoal);
    limitTotal = resLimitTotal.data.total;
    // console.log(limitTotal);
  }

  try {
    const res = await axios.get(restUrl);
    res.data.limitTotal = limitTotal;
    // console.log(res.data);
    res.data &&
      store.dispatch({ type: "get_" + nameSpace, data: res.data, toPage });
  } catch (error) {
    console.log(error);
  }
};

export default getDataTable;

export const delTableItem = async (nameSpace, key, keyName, endPoint, type) => {
  const url =
    type === 9
      ? endPoint + "/" + key + "/cancelhold"
      : endPoint + "/del/" + key;
  // console.log(url);
  const res = type === 9 ? await axios.put(url) : await axios.delete(url);
  if (res.data.result === 1) {
    getDataTable(nameSpace);
    return res;
  } else {
    window.alert(res.data.msg);
  }
};
