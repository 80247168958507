const initialState = [];

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "get_menus":
      return [...action.menus];
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default menuReducer;
