import React, { useEffect } from "react";
import styled from "styled-components";
import {
  FullWidthContainer,
  CCContainer,
  theme,
  SpanLGeneral,
  SpanBGeneral,
  ExlinkIcon,
} from "../../configurations/general-conf";
import { connect } from "react-redux";
import exLinkIcon from "../../resources/icons/ex_link.svg";
import axios from "axios";
import { getAccountStatus } from "../widgets/utilities";
import store from "../../store";
import TotalDraftCount from "../widgets/total-draft-count";
import { NavLink } from "react-router-dom";

const TopToolbarFlex = styled(CCContainer)`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${theme.bright};
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;

  & a {
    color: ${theme.bright};
  }
`;

const LogoutButton = styled.button`
  background: transparent;
  border: 1px solid ${theme.bright};
  color: ${theme.bright};
  padding: ${theme.proportion * 5}px ${theme.proportion * 18}px;
  margin-left: ${theme.proportion * 16}px;
  border-radius: ${theme.proportion * 5}px;
  cursor: pointer;

  & img {
    vertical-align: initial;
  }
`;

const StatusDot = styled.span`
  display: inline-block;
  border: 1px solid ${theme.bright};
  border-radius: 50%;
  background: ${(props) =>
    props.status === 1 || props.status === 4
      ? theme.passGreen
      : props.status === 2
      ? theme.warningYellow
      : theme.noticeRed};
  width: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
`;

const TopToolbarLayout = (props) => {
  // console.log(props.company);
  useEffect(() => {
    const company = JSON.parse(window.localStorage.getItem("company"));
    company && store.dispatch({ type: "get_company", company });
  }, []);

  const logout = () => {
    axios.defaults.headers.common["auth_token"] = null;
    store.dispatch({ type: "RESET" });
    // window.localStorage.clear();
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("company");
    Object.keys(store.asyncReducers).forEach(
      (key) => delete store.asyncReducers[key]
    );
    props.history.push("/login");
    // console.log(store);
  };

  return (
    <FullWidthContainer xheight="55" xbackgroundColor="charcoal">
      {props.company && props.company.customerName ? (
        <TopToolbarFlex>
          <div>
            {window.innerWidth > 640 && (
              <SpanLGeneral xpaddingLeft="16">Welcome</SpanLGeneral>
            )}{" "}
            <SpanBGeneral>
              {props.company.customerName}
              {window.innerWidth > 640 &&
                " - " + (props.company.oosLogin || props.company.repLogin)}
            </SpanBGeneral>
            <SpanBGeneral xpaddingLeft={window.innerWidth > 640 ? 16 : 5}>
              |
            </SpanBGeneral>
            {window.innerWidth > 640 && (
              <SpanLGeneral xpaddingLeft="16">Account</SpanLGeneral>
            )}{" "}
            <StatusDot status={props.company.status} />{" "}
            <SpanLGeneral>
              {getAccountStatus(props.company.status)}
            </SpanLGeneral>
          </div>
          {window.innerWidth > 640 && (
            <div>
              <SpanLGeneral>Your Sales Rep:</SpanLGeneral>{" "}
              <SpanBGeneral>
                {props.company.userUuid.replace("_", " ")}
              </SpanBGeneral>
            </div>
          )}
          <div>
            {JSON.parse(
              window.localStorage.getItem("company")
            ).oosLogin?.indexOf("Stock Check") < 0 && (
              <NavLink to="/draft_orders">
                <SpanLGeneral>Draft Orders:</SpanLGeneral> <TotalDraftCount />
              </NavLink>
            )}
            <SpanBGeneral xpaddingLeft={window.innerWidth > 640 ? 16 : 5}>
              |
            </SpanBGeneral>
            <LogoutButton onClick={logout}>
              <ExlinkIcon src={exLinkIcon} />
              <SpanBGeneral>Logout</SpanBGeneral>
            </LogoutButton>
          </div>
        </TopToolbarFlex>
      ) : null}
    </FullWidthContainer>
  );
};

// export default TopToolbarLayout;

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

export default connect(mapStateToProps)(TopToolbarLayout);
