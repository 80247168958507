import axios from "axios";
import { resturl } from "../configurations/general-conf";

// export function getUrlValue(name) {
//   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
//   var r = window.location.search.substr(1).match(reg);
//   if (r != null) return unescape(r[2]);
//   return null;
// }

// export function getCookie(c_name) {
//   if (document.cookie.length > 0) {
//     var c_start = document.cookie.indexOf(c_name + "=");
//     if (c_start !== -1) {
//       c_start = c_start + c_name.length + 1;
//       var c_end = document.cookie.indexOf(";", c_start);
//       if (c_end === -1) c_end = document.cookie.length;
//       return unescape(document.cookie.substring(c_start, c_end));
//     }
//   }
//   return "";
// }

// export function setCookie(c_name, value, expireMins) {
//   var exdate = new Date();
//   exdate.setTime(exdate.getTime() + expireMins * 60 * 1000);
//   document.cookie =
//     c_name +
//     "=" +
//     escape(value) +
//     (expireMins == null ? "" : ";expires=" + exdate.toGMTString());
// }

// export function delCookie(c_name) {
//   var exp = new Date();
//   exp.setTime(exp.getTime() + -1 * 24 * 60 * 60 * 1000);
//   var cval = getCookie(c_name);
//   document.cookie =
//     c_name + "=" + cval + "; Path=/; expires=" + exp.toGMTString();
// }

export function setAxiosDefaults() {
  axios.defaults.baseURL = resturl;
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  const authToken = window.localStorage.getItem("authToken");
  // console.log(authToken);
  if (authToken) {
    axios.defaults.headers.common["auth_token"] = authToken;
    return true;
  } else {
    return false;
  }
}
