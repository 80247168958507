import axios from "axios";
import store from "../store";
import _ from "lodash";

const getMenus = async () => {
  try {
    // console.log(axios.defaults);
    const res = await axios.get("/menus");
    if (
      res.data.result === -1022 &&
      axios.defaults.headers.common["auth_token"]
    ) {
      axios.defaults.headers.common["auth_token"] = null;
      store.dispatch({ type: "RESET" });
      // window.localStorage.clear();
      window.localStorage.removeItem("authToken");
      window.localStorage.removeItem("company");
      window.location.href = "/login";
    }
    const menus = res.data.objs
      ? _.orderBy(res.data.objs, "menuId", "asc")
      : null;
    menus && store.dispatch({ type: "get_menus", menus });
  } catch (error) {
    console.log(error);
    // window.location.href = "/login";
  }
};

export default getMenus;
