import { createStore } from "redux";
import createReducer from "./reducers";

function configureStore() {
  const store = createStore(createReducer());
  store.asyncReducers = {};
  return store;
}

const store = configureStore();
export default store;

export const addAsynDataTableReducer = (store, conf) => {
  const initialState = {};
  initialState.list = [];
  initialState.totalRecords = 0;
  initialState.pageSize = conf.pageSize ? conf.pageSize : 10;
  initialState.currentPage = 1;
  initialState.endPoint = conf.endPoint;
  conf.endPointLimiter &&
    (initialState.endPoint =
      initialState.endPoint +
      "?" +
      conf.endPointLimiter.limiterName +
      "=" +
      store.getState()[conf.endPointLimiter.nameSpace][
        conf.endPointLimiter.key
      ]);
  initialState.query = conf.query || "";
  initialState.qryStr = conf.qryStr || "";
  initialState.orderBy = conf.orderBy;
  initialState.queryLimitTotal = conf.queryLimitTotal || null;
  initialState.limitTotal = 0;

  const reducer = function (state = initialState, action) {
    switch (action.type) {
      case "get_" + conf.nameSpace:
        return {
          ...state,
          list: action.data.objs,
          totalRecords: action.data.total,
          limitTotal: action.data.limitTotal,
          currentPage: action.toPage,
        };
      case "del_single_" + conf.nameSpace:
        let listNew = [...state.list];
        listNew = listNew.filter((obj) => obj[action.keyName] !== action.key);
        return {
          ...state,
          list: listNew,
          totalRecords: state.totalRecords - 1,
        };
      case "change_size" + conf.nameSpace:
        return {
          ...state,
          currentPage: 1,
          pageSize: action.pageSize,
        };
      case "str_qry" + conf.nameSpace:
        return {
          ...state,
          currentPage: 1,
          qryStr: action.qryStr,
        };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  store.asyncReducers[conf.nameSpace] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

export const addAsynSimpleListReducer = (store, conf) => {
  const initialState = {};
  initialState.list = null;
  initialState.endPoint = conf.endPoint;

  const reducer = function (state = initialState, action) {
    switch (action.type) {
      case "get_" + conf.nameSpace:
        return {
          ...state,
          list: action.data.objs,
        };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  store.asyncReducers[conf.nameSpace] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

export const addPagingControlReducer = (store, conf, limiter) => {
  const initialState = {};
  initialState.pageSize = conf.pageSize ? conf.pageSize : 10;
  initialState.currentPage = 1;

  const reducer = function (state = initialState, action) {
    switch (action.type) {
      case "get_page_" + conf.nameSpace + limiter:
        return {
          ...state,
          currentPage: action.toPage,
        };
      case "change_size" + conf.nameSpace + limiter:
        return {
          ...state,
          currentPage: 1,
          pageSize: action.pageSize,
        };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  store.asyncReducers[conf.nameSpace + limiter] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

export const addSingleItemReducer = (store, conf, limiter) => {
  const initialState = {};
  initialState.heroImg = conf.initHeroImgKey;
  initialState.infoTab = "specs";
  initialState.tempOrdered = [];

  const reducer = function (state = initialState, action) {
    switch (action.type) {
      case "to_hero_" + conf.nameSpace + limiter:
        return {
          ...state,
          heroImg: action.toKey,
        };
      case "to_tab_" + conf.nameSpace + limiter:
        return {
          ...state,
          infoTab: action.toTab,
        };
      case "update_temp_order" + conf.nameSpace + limiter:
        return {
          ...state,
          tempOrdered: action.tempOrdered,
        };
      case "reset" + conf.nameSpace + limiter:
        return initialState;
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  store.asyncReducers[conf.nameSpace + limiter] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

export const addDraftOrder = (store) => {
  const initialState = {
    info2: null,
    customerDeliveryType: 2,
    saleItems: [],
    sampleItems: [],
  };

  const reducer = function (state = initialState, action) {
    switch (action.type) {
      case "load_draft_order":
        return action.data;
      case "inject_saleItemUuid":
        let saleItemsTI = [...state.saleItems];
        action.saleItems.map((item) => {
          const exsitIndexTI = saleItemsTI.findIndex(
            (obj) => obj.productUuid === item.productUuid
          );
          if (exsitIndexTI > -1) {
            saleItemsTI[exsitIndexTI].saleItemUuid = item.saleItemUuid;
            saleItemsTI[exsitIndexTI].updateFlag = 0;
          }
          return null;
        });
        // console.log(saleItemsTI);
        return {
          ...state,
          saleItems: saleItemsTI,
        };
      case "add_draft_order_items":
        let saleItems = [...state.saleItems];
        action.saleItems.map((item) => {
          const exsitIndex = saleItems.findIndex(
            (obj) => obj.productUuid === item.productUuid
          );
          if (exsitIndex > -1) {
            saleItems[exsitIndex].quantity += item.quantity;
            saleItems[exsitIndex].amt = parseFloat(
              (
                saleItems[exsitIndex].quantity *
                (item.productInfo.unitSize || 1)
              ).toFixed(
                item.productInfo.unitSize?.toString().split(".")[1]?.length || 0
              )
            );
            state.saleOrderUuid && (saleItems[exsitIndex].updateFlag = 2);
          } else {
            state.saleOrderUuid && (item.updateFlag = 1);
            // item.updateFlag = 1;
            saleItems.push(item);
          }
          return null;
        });
        return {
          ...state,
          saleItems,
        };
      case "add_draft_sample_items":
        let sampleItems = [...state.sampleItems];
        action.sampleItems.map((item) => {
          const exsitIndex = sampleItems.findIndex(
            (obj) => obj.sampleCombinationUuid === item.sampleCombinationUuid
          );
          if (item.quantity === 0) return null;
          if (exsitIndex > -1) {
            sampleItems[exsitIndex].quantity += item.quantity;
            state.saleOrderUuid && (sampleItems[exsitIndex].updateFlag = 2);
          } else {
            state.saleOrderUuid && (item.updateFlag = 1);
            sampleItems.push(item);
          }
          return null;
        });
        return {
          ...state,
          sampleItems,
        };
      case "change_draft_order_qty":
        let qtyChangeItems = [...state.saleItems];
        const qtyExistIndex = qtyChangeItems.findIndex(
          (obj) => obj.productUuid === action.productUuid
        );
        qtyChangeItems[qtyExistIndex].quantity = Math.ceil(action.value);
        qtyChangeItems[qtyExistIndex].amt = parseFloat(
          (
            qtyChangeItems[qtyExistIndex].quantity *
            (qtyChangeItems[qtyExistIndex].productInfo.unitSize || 1)
          ).toFixed(
            qtyChangeItems[qtyExistIndex].productInfo.unitSize
              ?.toString()
              .split(".")[1]?.length || 0
          )
        );
        qtyChangeItems[qtyExistIndex].saleItemUuid &&
          (qtyChangeItems[qtyExistIndex].updateFlag = 2);
        // qtyChangeItems[qtyExistIndex].updateFlag = 2;
        // console.log(qtyChangeItems);
        return {
          ...state,
          saleItems: qtyChangeItems,
        };
      case "change_draft_sample_qty":
        let sampleQtyChangeItems = [...state.sampleItems];
        const sampleQtyExistIndex = sampleQtyChangeItems.findIndex(
          (obj) => obj.sampleCombinationUuid === action.sampleCombinationUuid
        );
        sampleQtyChangeItems[sampleQtyExistIndex].quantity = Math.ceil(
          action.value
        );
        sampleQtyChangeItems[sampleQtyExistIndex].sampleCombinationUuid &&
          (sampleQtyChangeItems[sampleQtyExistIndex].updateFlag = 2);
        return {
          ...state,
          sampleItems: sampleQtyChangeItems,
        };
      case "remove_draft_order_item":
        let saleItemsRemain = [];
        if (state.saleOrderUuid) {
          saleItemsRemain = [...state.saleItems];
          const delIndex = saleItemsRemain.findIndex(
            (obj) => obj.productUuid === action.productUuid
          );
          saleItemsRemain[delIndex].updateFlag = -1;
        } else {
          saleItemsRemain = state.saleItems.filter(
            (obj) => obj.productUuid !== action.productUuid
          );
        }

        return {
          ...state,
          saleItems: saleItemsRemain,
        };
      case "remove_draft_sample_item":
        let sampleItemsRemain = [];
        sampleItemsRemain = [...state.sampleItems];
        const delIndex = sampleItemsRemain.findIndex(
          (obj) => obj.sampleCombinationUuid === action.sampleCombinationUuid
        );
        sampleItemsRemain[delIndex].updateFlag = -1;
        return {
          ...state,
          sampleItems: sampleItemsRemain,
        };
      case "change_draft_order_mainKey":
        return {
          ...state,
          [action.key]: action.value,
        };
      case "reset_draft_order":
        return initialState;
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  store.asyncReducers["draftOrder"] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

export const addCurrentStock = (store, nameSpace) => {
  const initialState = null;

  const reducer = function (state = initialState, action) {
    switch (action.type) {
      case "get_" + nameSpace:
        return action.data;
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };

  store.asyncReducers[nameSpace] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};
