import React from "react";
import { theme } from "../../configurations/general-conf";
import styled from "styled-components";
import dropdown from "../../resources/icons/dropdown.svg";

const PGNFlexWrapper = styled.div`
  display: flex;
  margin-top: ${theme.proportion * 25 * theme.mobileResizeFactor}px;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};
  align-items: center;
`;

const PGNContainer = styled.ul`
  display: flex;
  align-items: center;

  & li {
    color: ${theme.charcoal};
    font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
    font-family: ${theme.boldFont};
    border-right: 1px solid ${theme.darkerBiege};
    padding: 0 ${theme.proportion * 5 * theme.mobileResizeFactor}px;
  }

  & li:last-child {
    border-right: none;
  }

  & li button {
    border: none;
    background: none;
    color: ${theme.darkerBiege};
    font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
    font-family: ${theme.boldFont};
    cursor: pointer;
  }
`;

const PageSizeOptContainer = styled.div`
  margin-left: ${theme.proportion * 80 * theme.mobileResizeFactor}px;
`;

const PageSizeSelector = styled.select`
  border: 1px solid ${theme.biege};
  border-radius: 0;
  color: ${theme.darkerBiege};
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  font-family: ${theme.boldFont};
  margin-left: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  appearance: none;
  padding: ${theme.proportion * 2 * theme.mobileResizeFactor}px
    ${theme.proportion * 22 * theme.mobileResizeFactor}px
    ${theme.proportion * 2 * theme.mobileResizeFactor}px
    ${theme.proportion * 4 * theme.mobileResizeFactor}px;
  background: url(${dropdown}) no-repeat 100%;
  $ option {
    display: block;
  }
`;

const DataTablePGN = (props) => {
  const {
    currentPage,
    pageSize,
    totalRecords,
    pageSizeOpt,
    initPageSize,
    toPage,
    changePageSize,
    maxPage,
  } = props;
  const totalPage =
    maxPage > 0 && maxPage < Math.ceil(totalRecords / pageSize)
      ? maxPage
      : Math.ceil(totalRecords / pageSize);
  let pDOM = [];
  for (let i = 1; i <= totalPage; i++) {
    const linkStr = i.toString().length === 1 ? `0${i}` : i;
    // const tDOM =
    //   currentPage === i ? (
    //     <li key={`PGN_${i}`}>{linkStr}</li>
    //   ) : (
    //     <li key={`PGN_${i}`}>
    //       <button
    //         onClick={() => {
    //           toPage(i);
    //         }}
    //       >
    //         {linkStr}
    //       </button>
    //     </li>
    //   );
    // pDOM.push(tDOM);

    if (currentPage === i) {
      pDOM.push(<li key={`PGN_${i}`}>{linkStr}</li>);
    } else {
      if (totalRecords >= 200) {
        if (i === 1 || i === totalPage) {
          pDOM.push(
            <li key={`PGN_${i}`}>
              <button
                onClick={() => {
                  toPage(i);
                }}
              >
                {linkStr}
              </button>
            </li>
          );
        } else if (i > currentPage - 4 && i < currentPage + 4) {
          pDOM.push(
            <li key={`PGN_${i}`}>
              <button
                onClick={() => {
                  toPage(i);
                }}
              >
                {linkStr}
              </button>
            </li>
          );
        } else if (
          (i === currentPage - 4 && i >= 2) ||
          (i === currentPage + 4 && i < totalPage)
        ) {
          pDOM.push(<li key={`PGN_${i}`}>&nbsp;...&nbsp;</li>);
        } else {
          //output = false;
        }
      } else {
        pDOM.push(
          <li key={`PGN_${i}`}>
            <button
              onClick={() => {
                toPage(i);
              }}
            >
              {linkStr}
            </button>
          </li>
        );
      }
    }
  }

  let sDom = [];
  for (let i = 1; i <= Math.ceil(totalRecords / initPageSize); i++) {
    const optStr = i * initPageSize;
    const tDOM = (
      <option key={`OPT_${i}`} value={optStr}>
        {optStr}
      </option>
    );
    sDom.push(tDOM);
  }
  return (
    <PGNFlexWrapper>
      <PGNContainer>{pDOM.map((dom) => dom)}</PGNContainer>
      {pageSizeOpt && (
        <PageSizeOptContainer>
          Show results per page
          <PageSizeSelector
            onChange={(e) => {
              changePageSize(e.target.value);
            }}
            defaultValue={pageSize}
          >
            {sDom.map((dom) => dom)}
          </PageSizeSelector>
        </PageSizeOptContainer>
      )}
    </PGNFlexWrapper>
  );
};

export default DataTablePGN;
