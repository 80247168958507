import moment from "moment";

const trackOrdersAllConf = {
  title: "Track Your Orders",
  nameSpace: "todtall",
  endPoint: "/saleorders",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&status=1&qryFrom=" + moment().add(-90, "days").format("YYYY-MM-DD"),
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "There is no order to track yet.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No.", key: "info2" },
      {
        title: "Dispatch Date",
        key: "edd",
        type: "localTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Status",
        type: "orderSubStatus",
        key: "status",
        subKey: "deliveryFlag",
      },
      {
        title: "Tracking",
        type: "tracking",
        key: "freightTrackingLink",
        subKey: "fc",
        textAlign: "right",
      },
      {
        title: " ",
        type: "edit",
        limitKey: "status",
        allowed: [0, 2, 9, 10, 12],
        repAllowed: [0, 1, 2, 9, 10, 11, 12],
        furtherTestValue: 1,
        furtherLimitKey: "deliveryFlag",
        furtherAllowed: [3],
        furtherLimitUser: ["Joanne B2B"],
        hiddenIcon: true,
      },
    ],
  },
  maxPage: 99,
  pageSize: 10,
  pageSizeOpt: true,
};

export default trackOrdersAllConf;
