import React, { useState } from "react";
import {
  FormGeneral,
  LabelGeneral,
  InputGeneral,
  PGeneral,
  ButtonGeneral,
  theme,
  StyledCheckbox,
  fileurl,
} from "../../configurations/general-conf";
import styled from "styled-components";
// import { NavLink } from "react-router-dom";
import axios from "axios";
import getMenus from "../../api/menu-api";
import store from "../../store";
import Select from "react-select";

const LoginForm = styled(FormGeneral)`
  margin: ${theme.proportion * 35}px auto 0;

  @media only screen and (max-width: 640px) {
    width: auto;
  }
`;

const LoginFormLabel = styled(LabelGeneral)`
  font-size: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
  display: block;
`;

const LoginFormInput = styled(InputGeneral)`
  width: 100%;
  margin: ${theme.proportion * 10 * theme.mobileResizeFactor}px 0;

  @media only screen and (max-width: 640px) {
    height: ${theme.proportion * 30 * theme.mobileResizeFactor}px;
  }
`;

const TCFlexLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.proportion * 27}px;
`;

const LoginFormButton = styled(ButtonGeneral)`
  margin: ${theme.proportion * 20 * theme.mobileResizeFactor}px auto 0;
  width: ${theme.proportion * 180 * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 50 * theme.mobileResizeFactor}px;
  font-size: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
  display: block;
`;

const LoginFormButtonRep = styled(LoginFormButton)`
  width: ${theme.proportion * 240 * theme.mobileResizeFactor}px;
`;

const CustomerSelectWrapper = styled.div`
  margin: ${theme.proportion * 20 * theme.mobileResizeFactor}px auto 0;
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  & div {
    border-radius: 0;
    border-color: ${theme.charcoal};
  }
`;

const LoginContainer = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(true);
  const [err, setErr] = useState(null);
  const [stdMode, setStdMode] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [userTypeS, setUserTypeS] = useState(null);

  const stdLogin = (loginObj, user, userType) => {
    const authToken = loginObj.authToken;
    axios.defaults.headers.common["auth_token"] = authToken;
    window.localStorage.setItem("authToken", authToken);
    const company = loginObj.company || {};
    company.oosLogin = user || loginObj.regNo;
    company.repLogin = loginObj.regNo;
    company.userType = userTypeS || userType;
    // console.log(company);
    window.localStorage.setItem("company", JSON.stringify(company));
    err && setErr(null);
    getMenus();
    store.dispatch({ type: "get_company", company });
    // console.log(store.getState());
    if (company?.oosLogin?.indexOf("Stock Check") > -1) {
      props.history.push("checklevel");
    } else {
      props.history.push("/");
    }
  };

  const repLogin = async (loginObj) => {
    err && setErr(null);
    const authToken = loginObj.authToken;
    axios.defaults.headers.common["auth_token"] = authToken;

    try {
      const res = await axios.get("/customers/b2bmycustomer");
      if (res && res.data && res.data.result === 1) {
        // console.log(res.data.objs);
        setStdMode(false);
        let options = [];
        res.data.objs.map((item) => {
          return options.push({
            value: item.customerUuid,
            label: item.customerName,
          });
        });
        setCustomers(options);
      } else {
        setErr("There is an issue loading your profile.");
      }
    } catch (error) {
      setErr(error.toString());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email && password && terms) {
      try {
        const res = await axios.post("/authentication", {
          userName: email,
          passWord: password,
        });
        if (res && res.data && res.data.result === 1) {
          // console.log(res.data.objs[0]);

          const userType = res.data.objs[0]?.boardNum;
          setUserTypeS(userType);
          if (userType === 2) {
            stdLogin(res.data.objs[0], email, userType);
          } else if (userType === 3 || userType === 4) {
            repLogin(res.data.objs[0]);
          } else {
            setErr("You're not autorised to use AST B2B yet.");
          }
        } else {
          setErr("Authentication failed. Please check your credentials.");
        }
      } catch (error) {
        setErr(error.toString());
      }
    }
  };

  const handleRepLogin = async (e) => {
    e.preventDefault();
    if (selectedOption?.value) {
      // console.log(selectedOption);
      try {
        const res = await axios.post(
          "/authentication/loginas/" + selectedOption?.value
        );

        if (res && res.data && res.data.result === 1) {
          // console.log(res.data.objs[0]);
          stdLogin(res.data.objs[0], null);
        } else {
          window.alert("Authentication failed.");
        }
      } catch (error) {
        window.alert(error.toString());
      }
    }
  };

  return stdMode ? (
    <LoginForm xwidth="500" onSubmit={handleSubmit}>
      <LoginFormLabel htmlFor="email">User Name (email)*</LoginFormLabel>
      <LoginFormInput
        type="text"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <LoginFormLabel htmlFor="password">Password*</LoginFormLabel>
      <LoginFormInput
        type="password"
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <PGeneral xcolor="darkerBiege" xfontSize="10">
        *Denotes a mandatory field.
      </PGeneral>
      <TCFlexLayout>
        <StyledCheckbox>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
            required
          />
          <span></span>
        </StyledCheckbox>
        <PGeneral xfontSize="14" xlineHeight="20" xmarginLeft="12">
          I have read and agree to the{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href={fileurl + "/legal/terms_conditions.pdf"}
          >
            terms and conditions
          </a>
        </PGeneral>
      </TCFlexLayout>
      <LoginFormButton type="submit">Login</LoginFormButton>
      {err ? (
        <PGeneral
          xfontSize="14"
          xlineHeight="20"
          xtextAlign="center"
          xcolor="noticeRed"
          xmarginTop="20"
        >
          {err}
        </PGeneral>
      ) : null}
    </LoginForm>
  ) : (
    <LoginForm xwidth="500" onSubmit={handleRepLogin}>
      <LoginFormLabel>Select a customer</LoginFormLabel>

      <CustomerSelectWrapper>
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={customers}
        />
      </CustomerSelectWrapper>

      <LoginFormButtonRep type="submit">
        Login as this customer
      </LoginFormButtonRep>
    </LoginForm>
  );
};

export default LoginContainer;
