const tempOrdersConf = {
  title: "Your Temporary Hold Orders",
  nameSpace: "ytho",
  endPoint: "/saleorders",
  // delItemName: "temp. hold order",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&status=9",
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "You currently have no temporary hold orders.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No. / Order Name", key: "info2" },
      {
        title: "Create Date",
        key: "createDate",
        type: "localTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Time Left",
        key: "orderPlacedDate",
        type: "leftTime",
        // format: "DD/MM/yyyy",
      },
      {
        title: "Status",
        type: "orderStatus",
        key: "status",
        subKey: "deliveryFlag",
        textAlign: "right",
      },
      {
        title: " ",
        type: "edit",
        limitKey: "status",
        allowed: [9],
        // path: "/temporders/createnew/draftorder/",
      },
      {
        title: " ",
        type: "delete",
        limitKey: "status",
        allowed: [9],
      },
    ],
    btns: [
      {
        text: "Create New",
        type: "link",
        path: "/temporders/createnew",
        totalLimitKey: "maxTempHold",
      },
    ],
  },
  maxPage: 10,
  pageSize: 10,
  pageSizeOpt: true,
};

export default tempOrdersConf;
