import React, { useEffect } from "react";
import store, { addCurrentStock } from "../../store";
import { getProductStock } from "../../api/single-item-api";
import { connect } from "react-redux";
import {
  SpanBGeneral,
  SpanLGeneral,
  PGeneral,
} from "../../configurations/general-conf";
import { getWhname, getStockTD } from "./utilities";

const CurrentStock = (props) => {
  useEffect(() => {
    const { uuid } = props.match.params;
    // console.log(uuid);

    !store.getState()["currentStock" + uuid] &&
      addCurrentStock(store, "currentStock" + uuid);

    uuid && getProductStock(uuid);
  }, [props.match.params]);

  // console.log(props);
  const { stock, data } = props;

  return (
    <>
      {stock &&
        stock.map((item, i) => {
          return (
            <PGeneral key={i} xlineHeight="20">
              <SpanBGeneral xfontSize="14">
                {getWhname(store.getState()["company"]["info" + (i + 1)])}{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14">
                {getStockTD(
                  item,
                  data.unitSize,
                  data.unitSymbol,
                  data.unitName
                )}{" "}
                available
              </SpanLGeneral>
            </PGeneral>
          );
        })}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { uuid } = ownProps.match.params;

  return {
    stock: state["currentStock" + uuid],
  };
};

export default connect(mapStateToProps)(CurrentStock);
