import React from "react";
import { FullWHContainer, theme } from "../../configurations/general-conf";
import styled from "styled-components";
import DataTable from "../widgets/data-table";
import activeOrdersConf from "../../configurations/data-tables/active-orders";
import trackOrdersConf from "../../configurations/data-tables/track-orders";

const DashboardGridLayout = styled(FullWHContainer)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${theme.proportion * 20}px;

  & > *:first-child {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

const DashboardLayout = (props) => {
  return (
    <DashboardGridLayout>
      <DataTable conf={activeOrdersConf} {...props} />
      <DataTable conf={trackOrdersConf} {...props} />
    </DashboardGridLayout>
  );
};

export default DashboardLayout;
