const draftOrdersPGN = {
  title: "Your Draft Orders",
  nameSpace: "ydopgn",
  endPoint: "/saleorders",
  delItemName: "draft",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&status=0",
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "You currently have no active orders.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No. / Order Name", key: "info2" },
      {
        title: "Create Date",
        key: "createDate",
        type: "localTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Status",
        type: "orderStatus",
        key: "status",
        subKey: "deliveryFlag",
        textAlign: "right",
      },
      {
        title: " ",
        type: "edit",
        limitKey: "status",
        allowed: [0],
        path: "/orderonline/draftorder/",
      },
      {
        title: " ",
        type: "delete",
        limitKey: "status",
        allowed: [0],
      },
    ],
  },
  maxPage: 5,
  pageSize: 10,
  pageSizeOpt: true,
};

export default draftOrdersPGN;
