import React from "react";
import { FullWidthContainer } from "../../configurations/general-conf";
import { Route } from "react-router-dom";
import TopAdLayout from "./top-ad-layout";
import InfoPrjOrders from "../views/info-prj-orders";
import styled from "styled-components";

const TopInfoFlex = styled(FullWidthContainer)`
  display: flex;
  align-items: flex-end;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const TopInfoLayout = () => {
  return (
    <TopInfoFlex xheight="106" xmarginTop="10">
      <Route exact path="/" render={(props) => <TopAdLayout {...props} />} />
      <Route
        path="/orderonline"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/draft_orders"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/trackorder"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/orderhistory"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/checklevel"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/temporders"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/pricelists"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/sampleorder"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/promotioncodes"
        render={(props) => <InfoPrjOrders {...props} />}
      />
      <Route
        path="/returns"
        render={(props) => <InfoPrjOrders {...props} />}
      />
    </TopInfoFlex>
  );
};

export default TopInfoLayout;
