import React, { useEffect } from "react";
import styled from "styled-components";
import * as restful from "../../api/restful-api";
import {
  FullWHContainer,
  FullWidthContainer,
  CCContainer,
  theme,
} from "../../configurations/general-conf";
import HeaderLayout from "./header-layout";
import SideLayout from "./side-layout";
import TopInfoLayout from "./top-info-layout";
import StageLayout from "./stage-layout";
import FooterLayout from "./footer-layout";

const MainContainer = styled(FullWHContainer)`
  color: ${theme.charcoal};
`;

const MainGridLayout = styled(CCContainer)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.proportion * 10}px;

  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

const isToken = restful.setAxiosDefaults();

const MainLayout = (props) => {
  useEffect(() => {
    isToken || props.history.push("/login");
  }, [props.history]);
  return (
    <MainContainer>
      <HeaderLayout {...props} />
      <FullWidthContainer>
        <MainGridLayout>
          <SideLayout />
          <TopInfoLayout />
          <StageLayout />
        </MainGridLayout>
      </FullWidthContainer>
      <FooterLayout />
    </MainContainer>
  );
};

export default MainLayout;
