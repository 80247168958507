import React from "react";
import SearchContainer from "../containers/search-container";
import DataTable from "../widgets/data-table";
import getDataTable from "../../api/data-table-api";
import store from "../../store";

const TrackOrders = (props) => {
  const searchOrderConf = {
    name: "s_order",
    label: "Search Orders:",
    placeholder: "Start your search by typing in this field.",
    description: "Search by reference number or PO number.",
    handler: (value) => {
      const { nameSpace } = props.conf;
      store.dispatch({
        type: "str_qry" + nameSpace,
        qryStr: value,
      });
      getDataTable(nameSpace);
    },
  };

  return (
    <>
      <SearchContainer conf={searchOrderConf} />
      <DataTable conf={props.conf} {...props} />
    </>
  );
};

export default TrackOrders;
