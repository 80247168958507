import React from "react";
import styled from "styled-components";
import { theme } from "../../configurations/general-conf";
import { Route } from "react-router-dom";
import DashboardLayout from "./dashboard-layout";
import LoginLayout from "./login-layout";
import NewOrder from "./new-order";
import TitleCard from "../widgets/title-card";
import { SpanBGeneral } from "../../configurations/general-conf";
import DataTable from "../widgets/data-table";
import Pricelists from "../widgets/pricelists";
import draftOrdersPGN from "../../configurations/data-tables/draft-orders";
import TrackOrders from "./track-orders";
import trackOrdersAllConf from "../../configurations/data-tables/track-orders-all";
import historyOrdersConf from "../../configurations/data-tables/history-orders";
import tempOrdersConf from "../../configurations/data-tables/temp-orders";
import sampleOrdersConf from "../../configurations/data-tables/sample-orders";
import promotionCodesConf from "../../configurations/data-tables/promotion-codes";
import returnOrderConf from "../../configurations/data-tables/return-orders";
import ReturnOrder from "../widgets/return-order";

const MainContainer = styled.main`
  width: ${theme.proportion * 964}px;
  min-height: ${theme.proportion * 567}px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const StageLayout = () => {
  const company = JSON.parse(window.localStorage.getItem("company"));
  if (
    company?.oosLogin?.indexOf("Stock Check") > -1 &&
    window.location.href.indexOf("checklevel") < 0
  ) {
    window.location.href = "/checklevel";
  }
  return (
    <MainContainer>
      <Route
        exact
        path="/login"
        render={(props) => <LoginLayout {...props} />}
      />
      <Route
        exact
        path="/"
        render={(props) => <DashboardLayout {...props} />}
      />
      <Route
        path="/orderonline"
        render={(props) => <NewOrder dyTitle="Draft" {...props} />}
      />
      <Route
        path="/temporders/createnew"
        render={(props) => <NewOrder dyTitle="Temporary Hold" {...props} />}
      />
      <Route
        path="/sampleorder/createnew"
        render={(props) => <NewOrder dyTitle="Sample" {...props} />}
      />
      <Route
        path="/orderonline/allocatedorder"
        render={(props) => <NewOrder dyTitle="Allocated" {...props} />}
      />
      <Route
        path="/promotioncodes/createnew"
        render={(props) => <NewOrder dyTitle="Promotion Code" {...props} />}
      />
      <Route
        exact
        path="/returns/createnew"
        render={(props) => <ReturnOrder {...props} />}
      />
      <Route
        exact
        path="/returns/createnew/:uuid"
        render={(props) => <ReturnOrder {...props} />}
      />
      <Route
        path="/checklevel"
        render={(props) => <NewOrder dyTitle="Check Stock Levels" {...props} />}
      />
      <Route
        path="/draft_orders"
        render={(props) => <DataTable conf={draftOrdersPGN} {...props} />}
      />
      <Route
        path="/orderhistory"
        render={(props) => <TrackOrders conf={historyOrdersConf} {...props} />}
      />
      <Route
        path="/trackorder"
        render={(props) => <TrackOrders conf={trackOrdersAllConf} {...props} />}
      />
      <Route
        exact
        path="/temporders"
        render={(props) => <DataTable conf={tempOrdersConf} {...props} />}
      />
      <Route
        exact
        path="/pricelists"
        render={(props) => <Pricelists {...props} />}
      />
      <Route
        exact
        path="/sampleorder"
        render={(props) => <DataTable conf={sampleOrdersConf} {...props} />}
      />
      <Route
        exact
        path="/promotioncodes"
        render={(props) => <DataTable conf={promotionCodesConf} {...props} />}
      />
      <Route
        exact
        path="/returns"
        render={(props) => <DataTable conf={returnOrderConf} {...props} />}
      />
      {/* <Route
        path="/checklevel"
        render={(props) => (
          <TitleCard title="Check Stock Level" {...props}>
            <SpanBGeneral xfontSize="20" xmarginTop="20">
              Coming soon ...
            </SpanBGeneral>
          </TitleCard>
        )}
      /> */}
      <Route
        path="/claim"
        render={(props) => (
          <TitleCard title="Lodge a Claim" {...props}>
            <SpanBGeneral xfontSize="20" xmarginTop="20">
              Coming soon ...
            </SpanBGeneral>
          </TitleCard>
        )}
      />
    </MainContainer>
  );
};

export default StageLayout;
