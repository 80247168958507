import React from "react";
import styled from "styled-components";
import HeaderExlinksView from "../views/header-exlinks-view";
import TopToolbarLayout from "./top-toolbar-layout";

const Header = styled.header`
  width: 100%;
`;

const HeaderLayout = (props) => {
  return (
    <Header>
      <TopToolbarLayout {...props} />
      <HeaderExlinksView />
    </Header>
  );
};

export default HeaderLayout;
