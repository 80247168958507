import axios from "axios";
import React, { useState } from "react";
import store from "../../store";
import {
  FullWidthContainer,
  ExlinkIcon,
  SpanBGeneral,
  SpanLGeneral,
  PGeneral,
} from "../../configurations/general-conf";
import exLinkIcon from "../../resources/icons/ex_link.svg";
import refreshIcon from "../../resources/icons/refresh_dark.svg";
import { StockButton } from "./single-product";
import { getWhname, getStockTD } from "./utilities";

const CurrentStockMP = (props) => {
  const { product } = props;
  const [stock, setStock] = useState(null);

  const showStock = async (productUuid) => {
    const res = await axios.get("/products/ooscurrentstock/" + productUuid);
    setStock(res.data?.objs);
  };

  return (
    <FullWidthContainer xmarginTop="5">
      {stock ? (
        <PGeneral xlineHeight="20">
          {stock.map((item, i) => {
            return (
              <span key={i}>
                {i > 0 && (
                  <SpanLGeneral xmarginLeft="10" xmarginRight="10">
                    |
                  </SpanLGeneral>
                )}
                <SpanBGeneral xfontSize="12">
                  {getWhname(store.getState()["company"]["info" + (i + 1)])}{" "}
                </SpanBGeneral>
                <SpanLGeneral xfontSize="12">
                  {getStockTD(
                    item,
                    product.unitSize,
                    product.unitSymbol,
                    product.unitName
                  )}
                </SpanLGeneral>
              </span>
            );
          })}
          <SpanLGeneral xmarginLeft="10"></SpanLGeneral>
          <StockButton
            current={false}
            onClick={() => {
              setStock([]);
              showStock(product.productUuid);
            }}
          >
            <ExlinkIcon src={refreshIcon} />
            <SpanBGeneral>Refresh</SpanBGeneral>
          </StockButton>
        </PGeneral>
      ) : (
        <StockButton
          current={true}
          bright={true}
          onClick={() => {
            showStock(product.productUuid);
          }}
        >
          <ExlinkIcon src={exLinkIcon} />
          <SpanBGeneral>Stock Check</SpanBGeneral>
        </StockButton>
      )}
    </FullWidthContainer>
  );
};

export default CurrentStockMP;
