import React from "react";
import store from "../../store";
import { getWhname } from "./utilities";

const CurrentSampleStock = (props) => {
  const { data } = props;
  const stock = data.products[0]?.currentStock || 0;
  return (
    <>
      {stock &&
        stock.map((item, i) => {
          let stockSet = item;
          data.products.map((product) => {
            stockSet =
              product.currentStock[i] < stockSet
                ? product.currentStock[i]
                : stockSet;
            return null;
          });
          return (
            getWhname(store.getState()["company"]["info" + (i + 1)]) && (
              <div key={i}>
                <span>
                  {getWhname(store.getState()["company"]["info" + (i + 1)])}{" "}
                </span>
                {stockSet} sets available
              </div>
            )
          );
        })}
    </>
  );
};

export default CurrentSampleStock;
