import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../configurations/general-conf";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import getMenus from "../../api/menu-api";

const Sidebar = styled.aside`
  width: ${theme.proportion * 182}px;
  margin-top: ${theme.proportion * 128}px;
  min-height: ${theme.proportion * 567}px;
  grid-row: 1 / span 2;

  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: -${theme.proportion * 30 * theme.mobileResizeFactor}px;
    min-height: ${theme.proportion * 154 * theme.mobileResizeFactor}px;
    padding-left: ${theme.proportion * 192 * theme.mobileResizeFactor}px;
  }
`;

const SideMenuContainer = styled.ul`
  @media only screen and (max-width: 640px) {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: ${theme.proportion * 8 * theme.mobileResizeFactor}px;
    row-gap: ${theme.proportion * 9 * theme.mobileResizeFactor}px;
  }
`;

const SidebarMenuItem = styled.li`
  background-color: ${theme.biege};
  border-bottom: 1px solid ${theme.bright};

  @media only screen and (max-width: 640px) {
    height: ${theme.proportion * 29 * theme.mobileResizeFactor}px;
    border-bottom: 1px solid ${theme.biege};
  }
`;

const activeClassName = "menuActive";

const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  display: block;
  color: ${theme.charcoal};
  font-size: ${theme.proportion * 12}px;
  font-family: ${theme.boldFont};
  line-height: ${theme.proportion * 47}px;
  padding: 0 0 0 ${theme.proportion * 16}px;
  &.${activeClassName} {
    border-right: ${theme.proportion * 9}px solid ${theme.astGreen};
  }
  &:hover {
    border-right: ${theme.proportion * 9}px solid ${theme.astGreen};
  }

  @media only screen and (max-width: 640px) {
    font-size: ${theme.proportion * 13 * theme.mobileResizeFactor}px;
    line-height: ${theme.proportion * 29 * theme.mobileResizeFactor}px;
    border-bottom: ${theme.proportion * 9}px solid ${theme.biege};

    &.${activeClassName} {
      border-right: none;
      border-bottom: ${theme.proportion * 9}px solid ${theme.astGreen};
    }
    &:hover {
      border-right: none;
      border-bottom: ${theme.proportion * 9}px solid ${theme.astGreen};
    }
  }
`;

const SideLayout = (props) => {
  const company = JSON.parse(window.localStorage.getItem("company"));
  useEffect(() => {
    window.localStorage.getItem("authToken") && getMenus();
  }, []);
  // console.log(props.menus);
  return (
    <Sidebar>
      {props.menus.length > 0 ? (
        <SideMenuContainer>
          {company?.oosLogin?.indexOf("Stock Check") < 0 && (
            <SidebarMenuItem>
              <StyledNavLink exact to="/">
                Dashboard
              </StyledNavLink>
            </SidebarMenuItem>
          )}
          {props.menus.map((menu) => {
            const showMenu =
              menu.menuDesc === "RRR"
                ? company?.repLogin
                  ? menu.menuLink === "/promotioncodes" &&
                    company.userType === 3
                    ? false
                    : true
                  : false
                : true;
            return (
              showMenu && (
                <SidebarMenuItem key={menu.menuId}>
                  <StyledNavLink to={menu.menuLink}>
                    {menu.menuName}
                  </StyledNavLink>
                </SidebarMenuItem>
              )
            );
          })}
        </SideMenuContainer>
      ) : null}
    </Sidebar>
  );
};

const mapStateToProps = (state) => {
  return { menus: state.menus };
};

export default connect(mapStateToProps)(SideLayout);
