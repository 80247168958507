import moment from "moment";

export const formatNumberComma = (x, n) => {
  return x
    .toFixed(n)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getOrderTypes = (n) => {
  switch (n) {
    case 0:
      return "Draft";
    case 1:
      return "Processing";
    case 2:
      return "Pending";
    case 9:
      return "Temp. Hold";
    case 10:
      return "Future Order";
    case 11:
      return "WOS";
    case 12:
      return "Back Order";
    case 100:
      return "Draft Sample";
    case 101:
      return "Processing Sample";
    case 102:
      return "Pending Sample";
    case 111:
      return "WOS Sample";
    case 112:
      return "Back Order Sample";
    default:
      return null;
  }
};

export const getOrderTypesDisplayNameCap = (n) => {
  switch (n) {
    case 0:
      return "Draft";
    case 1:
      return "Allocated Order";
    case 2:
      return "Pending Order";
    case 9:
      return "Temp. Hold Order";
    case 10:
      return "Future Order";
    case 12:
      return "Back Order";
    case 100:
      return "Draft Sample Order";
    case 101:
      return "Processing Sample Order";
    case 102:
      return "Pending Sample Order";
    case 111:
      return "WOS Sample Order";
    case 112:
      return "Back Sample Order";
    default:
      return null;
  }
};

export const getOrderTypesDisplayNameLow = (n) => {
  switch (n) {
    case 0:
      return "draft";
    case 1:
      return "allocated order";
    case 2:
      return "pending order";
    case 9:
      return "temp. hold order";
    case 10:
      return "future order";
    case 12:
      return "back order";
    case 100:
      return "draft sample order";
    case 101:
      return "processing sample order";
    case 102:
      return "pending sample order";
    case 111:
      return "wos sample order";
    case 112:
      return "back sample order";
    default:
      return null;
  }
};

export const getOrderStatus = (n, p) => {
  switch (n) {
    case 0:
      return "Draft";
    case 1:
    case 101:
      switch (p) {
        case 0:
          return null;
        case 1:
          return "Stock Allocated";
        case 2:
          return "Stock Ready";
        case 3:
          return "Order Dispatched";
        case 5:
          return "Order Transferring";
        case 6:
          return "Stock Ready";
        case 9:
          return "Order Dispatched";
        default:
          return null;
      }
    case 2:
      return "Pending";
    case 9:
      return "Temp. Hold";
    case 10:
      return "Future Order";
    case 11:
      return "WOS";
    case 12:
      return "Back Order";
    case 100:
      return "Draft Sample Order";
    case 102:
      return "Pending Sample Order";
    case 111:
      return "WOS Sample Order";
    case 112:
      return "Back Sample Order";
    default:
      return null;
  }
};

export const getOrderStatusColor = (n) => {
  switch (n) {
    case 0:
      return "draftPantone";
    case 1:
      return "lightBlue";
    case 2:
      return "warningYellow";
    case 9:
      return "noticeRed";
    case 10:
      return "infoPurple";
    case 11:
      return "waitingPink";
    case 12:
      return "backPantone";
    case 100:
      return "draftPantone";
    case 101:
      return "lightBlue";
    case 102:
      return "warningYellow";
    case 111:
      return "waitingPink";
    case 112:
      return "backPantone";
    default:
      return null;
  }
};

export const getSubStatusColor = (n, p) => {
  switch (n) {
    case 0:
      return "draftPantone";
    case 1:
    case 101:
      switch (p) {
        case 0:
          return null;
        case 1:
          return "lightBlue";
        case 2:
          return "warningYellow";
        case 3:
          return "astGreen";
        case 5:
          return "lightBlue";
        case 6:
          return "warningYellow";
        case 9:
          return "astGreen";
        default:
          return null;
      }
    case 2:
      return "warningYellow";
    case 9:
      return "noticeRed";
    case 10:
      return "infoPurple";
    case 11:
      return "waitingPink";
    case 12:
      return "backPantone";
    case 100:
      return "draftPantone";
    case 102:
      return "warningYellow";
    case 111:
      return "waitingPink";
    case 112:
      return "backPantone";
    default:
      return null;
  }
};

export const getReturnOrderStatus = (n) => {
  switch (n) {
    case 0:
      return "Draft";
    case 1:
      return "Pending Return of Stock";
    case 2:
      return "Stock Received"; // Stock Received (scanned)
    case 3:
      return "Stock Received"; // Stock Received (confirmed)
    case 4:
      return "Return Processed";
    case 10:
      return "Cancelled";
    default:
      return null;
  }
};

export const getReturnOrderStatusColor = (n) => {
  switch (n) {
    case 0:
      return "lightBlue";
    case 1:
      return "infoPurple";
    case 2:
      return "warningYellow";
    case 3:
      return "warningYellow";
    case 4:
      return "passGreen";
    case 10:
      return "noticeRed";
    default:
      return null;
  }
};

export const getEarliestEdd = (dtype) => {
  let earliestEdd = moment().format("YYYY-MM-DD");
  const hoursNow = moment().get("hour");
  if (hoursNow >= 11 || dtype < 2) {
    earliestEdd = moment().add(1, "days").format("YYYY-MM-DD");
  }
  return earliestEdd;
};

export const getDefaultEdd = () => {
  const today = new Date().getDay();
  let defaultEdd = moment().add(2, "days").format("YYYY-MM-DD");
  if (today === 4 || today === 5) {
    defaultEdd = moment().add(4, "days").format("YYYY-MM-DD");
  }
  if (today === 6) {
    defaultEdd = moment().add(3, "days").format("YYYY-MM-DD");
  }
  return defaultEdd;
};

export const getEarliestCdt = (edd) => {
  return edd <= moment().format("YYYY-MM-DD") ? "13:00" : "07:00";
};

export const getWhname = (whUuid) => {
  switch (whUuid) {
    case "chesterhill":
      return "SYD";
    case "dandenong":
      return "MEL";
    case "perth":
      return "PER";
    case "brisbane":
      return "BNE";
    default:
      return null;
  }
};

export const getStockTD = (qty, unitSize, unitSymbol, unitName) => {
  const us = unitSymbol?.split("|")[0];
  if (us === "m2" || us === "m²") {
    return (qty * unitSize).toFixed(2) + " " + us;
  } else {
    return qty + " " + unitName?.split("|")[1];
  }
};

export const getAccountStatus = (n) => {
  switch (n) {
    case 1:
      return "EOM 30 Days";
    case 2:
      return "Prepaid";
    case 3:
      return "Credit Hold";
    case 4:
      return "Net 15 Days";
    default:
      return null;
  }
};

export const getCatListTitle = (dt) => {
  switch (dt) {
    case "Draft":
      return "Order Online";
    case "Check Stock Levels":
      return "Check Stock Levels";
    case "Temporary Hold":
      return "Create Temp. Hold Order";
    case "Sample":
      return "Create Sample Order";
    default:
      return "Select Range";
  }
};

export const getAmtByQty = (qty, unitSize) => {
  return Number(
    parseFloat(qty * (unitSize || 1)).toFixed(
      unitSize?.toString().split(".")[1]?.length || 0
    )
  );
};

export const getDyPath = (n, subType) => {
  if (subType === "RO") return "/returns/createnew/";
  switch (n) {
    case 0:
      return "/orderonline/draftorder/";
    case 1:
      return "/orderonline/allocatedorder/draftorder/";
    case 2:
      return "/orderonline/draftorder/";
    case 9:
      return "/temporders/createnew/draftorder/";
    case 10:
      return "/orderonline/draftorder/";
    case 11:
      return "/orderonline/allocatedorder/draftorder/";
    case 12:
      return "/orderonline/draftorder/";
    case 100:
      return "/sampleorder/createnew/draftorder/";
    case 112:
      return "/sampleorder/createnew/draftorder/";
    default:
      return null;
  }
};

export const getCatsFromProducts = (products, alias) => {
  let cats = [];
  products.forEach((p) => {
    const index = cats.findIndex(
      (c) => c.productCategoryUuid === p.productCategoryUuid || c.productCategoryName === (alias? (p.info1 || p.productCategoryName) : p.productCategoryName) 
    );
    if (index < 0) {
      cats.push({
        productCategoryUuid: p.productCategoryUuid,
        productCategoryName: alias? (p.info1 || p.productCategoryName) : p.productCategoryName,
        productcCategoryImage: p.info4,
      });
    }
  });
  return cats;
};
