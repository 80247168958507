const initialState = {};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "get_company":
      return { ...action.company };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default companyReducer;
