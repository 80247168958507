import React, { useEffect } from "react";
import { SpanBGeneral } from "../../configurations/general-conf";
import { connect } from "react-redux";
import draftOrderAll from "../../configurations/data-tables/draft-orders-all";
import store, { addAsynDataTableReducer } from "../../store";
import getDataTable from "../../api/data-table-api";

const TotalDraftCount = (props) => {
  useEffect(() => {
    const { nameSpace, endPoint, loadingDelay } = draftOrderAll;
    nameSpace &&
      !store.asyncReducers[nameSpace] &&
      addAsynDataTableReducer(store, draftOrderAll);

    if (loadingDelay && nameSpace && endPoint) {
      setTimeout(() => getDataTable(nameSpace), loadingDelay);
    } else {
      nameSpace && endPoint && getDataTable(nameSpace);
    }
  }, []);

  const draftCount = props.data?.totalRecords;
  const draftNumberStr =
    draftCount?.toString().length === 1 ? `0${draftCount}` : draftCount;

  // console.log(props.data);
  return <SpanBGeneral>{draftNumberStr}</SpanBGeneral>;
};

const mapStateToProps = (state) => {
  return {
    data: state[draftOrderAll.nameSpace] || {},
  };
};

export default connect(mapStateToProps)(TotalDraftCount);
