import axios from "axios";
import store from "../store";

const getSimpleList = async (nameSpace, dyPath) => {
  if(nameSpace === "prdsmpc") {
    const sampleList = JSON.parse(window.localStorage.getItem("sampleList"));
    sampleList && store.dispatch({ type: "get_" + nameSpace, data: sampleList });
  }
  
  const { endPoint } = store.getState()[nameSpace];

  try {
    const res = await axios.get(endPoint);
    // console.log(res.data);
    nameSpace === "prdcs" &&
      dyPath !== "/promotioncodes/createnew" &&
      res.data.objs.map((obj) => {
        if (obj?.productCategoryName.indexOf(obj?.displayName) < 0) {
          obj?.products.map((item) => {
            return (item.productCategoryName = obj?.productCategoryName);
          });
        }
        return null;
      });
    res.data && store.dispatch({ type: "get_" + nameSpace, data: res.data });
    if(nameSpace === "prdsmpc") {
      // console.log(res.data);
      window.localStorage.setItem("sampleList", JSON.stringify(res.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export default getSimpleList;
