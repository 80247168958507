import React from "react";
import SearchContainer from "../containers/search-container";
import ProdCatsList from "../widgets/product-cats";
import mainPrdCatsConf from "../../configurations/thumb-lists/main-prd-cats";
import samplePrdCatsConf from "../../configurations/thumb-lists/sample-prod-cats";
import { Route } from "react-router-dom";
import ProductList from "../widgets/product-list";
import prdByCat from "../../configurations/thumb-lists/prd-by-cat";
import smpByCat from "../../configurations/thumb-lists/smp-by-cat";
import prdBySearch from "../../configurations/thumb-lists/prd-by-search";
import SingleProduct from "../widgets/single-product";
import prdDetials from "../../configurations/thumb-lists/prd-details";
import DraftOrder from "../widgets/draft-order";

const NewOrder = (props) => {
  const searchProductConf = {
    name: "s_prod",
    label: "Search Product:",
    placeholder: "Start your search by typing in this field.",
    description: "Search by product name and/or board length.",
    handler: (value) => {
      const to = dyPath + "/search/" + value;
      props.history.push(to);
    },
  };

  const navHanlderCat = (uuid) => {
    const to =
      props.history.location.pathname.replace("/addproduct", "") +
      "/cat/" +
      uuid;
    props.history.push(to);
  };

  const dyPath = props.match?.path;
  const dyTitle = props.dyTitle;
  // console.log(dyTitle);

  const prdCatsConf =
    dyTitle === "Sample" ? samplePrdCatsConf : mainPrdCatsConf;

  const prdListConf = dyTitle === "Sample" ? smpByCat : prdByCat;

  return (
    <>
      {dyTitle === "Sample" || dyTitle === "Promotion Code" || dyTitle === "Allocated" ? null : (
        <SearchContainer conf={searchProductConf} />
      )}
      <Route
        exact
        path={dyPath}
        render={(props) => (
          <ProdCatsList
            {...props}
            dyTitle={dyTitle}
            conf={prdCatsConf}
            navHanlder={navHanlderCat}
          />
        )}
      />
      <Route
        exact
        path={dyPath + "/addproduct"}
        render={(props) => (
          <ProdCatsList
            {...props}
            conf={prdCatsConf}
            navHanlder={navHanlderCat}
            mode="addproduct"
          />
        )}
      />
      <Route
        exact
        path={dyPath + "/cat/:uuid"}
        render={(props) => (
          <ProductList {...props} conf={prdListConf} dyPath={dyPath} />
        )}
      />
      <Route
        exact
        path={dyPath + "/search/:keyword"}
        render={(props) => (
          <ProductList {...props} conf={prdBySearch} dyPath={dyPath} />
        )}
      />
      <Route
        exact
        path={dyPath + "/product/:uuid"}
        render={(props) => (
          <SingleProduct {...props} conf={prdDetials} dyPath={dyPath} />
        )}
      />
      <Route
        exact
        path={dyPath + "/draftorder"}
        render={(props) => (
          <DraftOrder dyTitle={dyTitle} dyPath={dyPath} {...props} />
        )}
      />
      <Route
        exact
        path={dyPath + "/draftorder/:uuid"}
        render={(props) => (
          <DraftOrder
            dyTitle={dyTitle}
            dyPath={dyPath}
            {...props}
            mode="edit"
          />
        )}
      />
      <Route
        exact
        path={dyPath + "/convert"}
        render={(props) => (
          <DraftOrder
            dyTitle={dyTitle}
            dyPath={dyPath}
            {...props}
            mode="checkout"
          />
        )}
      />
    </>
  );
};

export default NewOrder;
