import React, { useState } from "react";
import TitleCard from "./title-card";
import {
  FullWidthContainer,
  TableGeneral,
  ThGeneral,
  TdGeneral,
  ButtonFuncR,
  LinkButtonR,
  fileurl,
} from "../../configurations/general-conf";

import moment from "moment";
import axios from "axios";

const Pricelists = (props) => {
  const company = JSON.parse(window.localStorage.getItem("company"));
  const {
    priceListEd,
    priceListPlanEd,
    priceListUuid,
    priceListPlanUuid,
    customerUuid,
  } = company;
  //   console.log(company);

  //pricelist download control
  const [cpdc, setCpdc] = useState("Generate");
  const [fpdc, setFpdc] = useState("Generate");
  //useEffect(() => {}, []);

  const genPL = async (e, mode) => {
    e.preventDefault();
    if (mode === "current") {
      setCpdc("Generating...");
      const endPoint = "/reports/customer/pdf/" + customerUuid;
      try {
        const res = await axios.put(endPoint);
        if (res.data?.msg === "") {
          setCpdc("Generate");
          alert("Error generating price list. Please try again later.");
        }
        res.data?.msg && setCpdc(fileurl + res.data?.msg);
      } catch (error) {
        console.log(error);
      }
    }

    if (mode === "future") {
      setFpdc("Generating...");
      const endPoint = "/reports/customer/planpdf/" + customerUuid;
      try {
        const res = await axios.put(endPoint);
        if (res.data?.msg === "") {
          setFpdc("Generate");
          alert("Error generating price list. Please try again later.");
        }
        res.data?.msg && setFpdc(fileurl + res.data?.msg);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <TitleCard title="Price Lists" contentPadding="25">
      <FullWidthContainer>
        <TableGeneral>
          <thead>
            <tr>
              <ThGeneral>Price List</ThGeneral>
              <ThGeneral>Effective Date</ThGeneral>
              <ThGeneral>&nbsp;</ThGeneral>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TdGeneral>Current Price List</TdGeneral>
              <TdGeneral>
                {priceListEd
                  ? moment(priceListEd).add(6, "hours").format("DD/MM/yyyy")
                  : "N/A"}
              </TdGeneral>
              <TdGeneral xpadding="5">
                {priceListUuid ? (
                  cpdc.indexOf("/pricelists") < 0 ? (
                    <ButtonFuncR
                      onClick={(e) => genPL(e, "current")}
                      disabled={cpdc === "Generating..." ? true : false}
                    >
                      {cpdc}
                    </ButtonFuncR>
                  ) : (
                    <LinkButtonR target="_blank" href={cpdc}>
                      Download Price List
                    </LinkButtonR>
                  )
                ) : (
                  "N/A"
                )}
              </TdGeneral>
            </tr>
            <tr>
              <TdGeneral>Future Price List</TdGeneral>
              <TdGeneral>
                {priceListPlanEd
                  ? moment(priceListPlanEd).add(6, "hours").format("DD/MM/yyyy")
                  : "N/A"}
              </TdGeneral>
              <TdGeneral xpadding="5">
                {priceListPlanUuid ? (
                  fpdc.indexOf("/pricelists") < 0 ? (
                    <ButtonFuncR
                      onClick={(e) => genPL(e, "future")}
                      disabled={fpdc === "Generating..." ? true : false}
                    >
                      {fpdc}
                    </ButtonFuncR>
                  ) : (
                    <LinkButtonR target="_blank" href={fpdc}>
                      Download Price List
                    </LinkButtonR>
                  )
                ) : (
                  "N/A"
                )}
              </TdGeneral>
            </tr>
          </tbody>
        </TableGeneral>
      </FullWidthContainer>
    </TitleCard>
  );
};

export default Pricelists;
