import React, { useEffect } from "react";
import store, { addCurrentStock } from "../../store";
import { getFutureStock } from "../../api/single-item-api";
import { connect } from "react-redux";
import {
  SpanBGeneral,
  SpanLGeneral,
  PGeneral,
} from "../../configurations/general-conf";
import { getWhname, getStockTD } from "./utilities";
import moment from "moment";

const FutureStock = (props) => {
  useEffect(() => {
    const { uuid } = props.match.params;
    // console.log(uuid);

    !store.getState()["futureStock" + uuid] &&
      addCurrentStock(store, "futureStock" + uuid);

    uuid && getFutureStock(uuid);
  }, [props.match.params]);

  // console.log(props);
  const { stock, data } = props;

  return (
    <>
      {stock &&
        stock.map((item, i) => {
          return (
            <PGeneral key={i} xlineHeight="20">
              <SpanBGeneral xfontSize="14">
                {getWhname(item.whUuid)}{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14">
                {getStockTD(
                  item.dispatchQty,
                  data.unitSize,
                  data.unitSymbol,
                  data.unitName
                )}{" "}
                available
              </SpanLGeneral>
              <SpanBGeneral xfontSize="14" xmarginLeft="14">
                ETA{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14" xcolor="astGreen">
                {moment(item.eta).format("DD/MM/YYYY")}
              </SpanLGeneral>
            </PGeneral>
          );
        })}
      {stock?.length === 0 && (
        <PGeneral xlineHeight="20">There is no shipment currently.</PGeneral>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { uuid } = ownProps.match.params;

  return {
    stock: state["futureStock" + uuid],
  };
};

export default connect(mapStateToProps)(FutureStock);
