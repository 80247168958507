const draftOrderAll = {
  title: "Draft Orders All",
  nameSpace: "doa",
  endPoint: "/saleorders",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&status=0",
  pageSize: 999,
  loadingDelay: 500,
};

export default draftOrderAll;
