const sampleOrdersConf = {
  title: "Your Sample Orders",
  nameSpace: "ysmplo",
  endPoint: "/sampleorders",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&status=100,101,102,112",
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "You currently have no sample orders.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No. / Order Name", key: "info2" },
      {
        title: "Create Date",
        key: "createDate",
        type: "localTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Status",
        type: "orderSubStatus",
        key: "status",
        subKey: "deliveryFlag",
        textAlign: "right",
      },
      {
        title: " ",
        type: "edit",
        limitKey: "status",
        allowed: [100, 112],
      },
      {
        title: " ",
        type: "delete",
        limitKey: "status",
        allowed: [100, 112],
      },
      {
        title: " ",
        type: "doc",
        limitKey: "status",
        allowed: [101, 102],
        limitKey2: "saleOrderName",
        urlKey: "orderSummaryUrl",
      },
    ],
    btns: [
      {
        text: "Create New",
        type: "link",
        path: "/sampleorder/createnew",
      },
    ],
  },
  maxPage: 10,
  pageSize: 10,
  pageSizeOpt: true,
};

export default sampleOrdersConf;
