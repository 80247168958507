import React from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../configurations/general-conf";
import footerLogos from "../../resources/footer_logos.svg";
import FooterBannerLayout from "./footer-banner-layout";

const Footer = styled.footer`
  width: 100%;
  padding-top: ${theme.proportion * 50}px;
`;

const FooterLogos = styled.img`
  margin: ${theme.proportion * 50}px auto 0;
  width: ${theme.proportion * 838}px;

  @media only screen and (max-width: 640px) {
    width: 90%;
  }
`;

const FooterLegal = styled.p`
  padding: ${theme.proportion * 50}px 0;
  text-align: center;
  color: ${theme.darkerBiege};
  font-family: ${theme.romanFont};
  font-size: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  & a {
    color: ${theme.darkerBiege};
  }
  & a:hover {
    color: ${theme.charcoal};
  }
`;

const FooterLayout = () => {
  return (
    <Footer>
      <Route
        exact
        path="/"
        render={(props) => <FooterBannerLayout {...props} />}
      />
      <FooterLogos src={footerLogos} />
      <FooterLegal>
        © Copyright 2021 by Australian Select Timbers Pty
        Ltd&nbsp;&nbsp;|&nbsp;&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.asttimbers.com.au/disclaimer/"
        >
          Disclaimer
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.asttimbers.com.au/tnc/"
        >
          Terms and Conditions
        </a>
      </FooterLegal>
    </Footer>
  );
};

export default FooterLayout;
