const samplePrdCatsConf = {
  title: "Sample Categories",
  nameSpace: "prdsmpc",
  endPoint: "/productcategories/samplecombinations",
  listCfg: {
    mainKey: "productCategoryUuid",
    noDataInfo: "No sample category available.",
    columns: [
      { key: "cover1", type: "cat_cover", altKey: "productCategoryName" },
    ],
  },
};

export default samplePrdCatsConf;
