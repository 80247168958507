import React, { useEffect } from "react";
import { connect } from "react-redux";
import TitleCard from "./title-card";
import store, { addAsynSimpleListReducer } from "../../store";
import getSimpleList from "../../api/simple-list-api";
import ThumbListView from "../views/thumb-list-view";
import { getCatListTitle } from "../widgets/utilities";

const ProdCatsList = (props) => {
  useEffect(() => {
    props.mode !== "addproduct" &&
      store.dispatch({
        type: "reset_draft_order",
      });
    const { nameSpace, endPoint } = props.conf;
    nameSpace &&
      !store.getState()[nameSpace] &&
      addAsynSimpleListReducer(store, props.conf);
    nameSpace && endPoint && getSimpleList(nameSpace);
  }, [props.conf, props.mode]);

  const { listCfg } = props.conf;
  const { list } = props.data;

  const dyTitle = getCatListTitle(props.dyTitle);

  // list?.length > 0 && console.log(props);
  return (
    <TitleCard title={dyTitle} contentPadding="25">
      <ThumbListView
        listCfg={listCfg}
        list={list}
        imgClick={props.navHanlder}
      />
    </TitleCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { nameSpace } = ownProps.conf;
  return {
    data: state[nameSpace] || {},
  };
};

export default connect(mapStateToProps)(ProdCatsList);
