import React from "react";
import {
  ConWindowFrame,
  H2Title,
  PLGeneral,
  ButtonFuncR,
} from "../../configurations/general-conf";

const SimpleConfirmation = (props) => {
  const { title, message, onClose } = props;
  return (
    <ConWindowFrame>
      <H2Title xfontSize="20" xmarginBottom="24">
        {title}
      </H2Title>
      <PLGeneral xfontSize="14" xlineHeight="18" xmarginBottom="24">
        {message}
      </PLGeneral>
      <ButtonFuncR onClick={onClose} xwidth="100">
        OK
      </ButtonFuncR>
    </ConWindowFrame>
  );
};

export default SimpleConfirmation;
