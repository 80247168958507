import React, { useState } from "react";
import {
  FormGeneral,
  LabelGeneral,
  PGeneral,
  theme,
} from "../../configurations/general-conf";
import styled from "styled-components";
import searchIcon from "../../resources/icons/search.svg";

const SearchForm = styled(FormGeneral)`
  display: flex;
  align-items: center;
  padding-bottom: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
`;

const SearchInput = styled.input`
  background: none;
  border: 1px solid ${theme.biege};
  margin-left: ${theme.proportion * 15}px;
  padding: 0 ${theme.proportion * 45 * theme.mobileResizeFactor}px 0
    ${theme.proportion * 15 * theme.mobileResizeFactor}px;
  font-style: italic;
  font-family: ${theme.lightFont};
  height: ${theme.proportion * 37 * theme.mobileResizeFactor}px;
  width: ${theme.proportion * 530}px;

  @media only screen and (max-width: 640px) {
    margin-left: ${theme.proportion * 0}px;
    width: 100%;
  }
`;

const SearchIcon = styled.input`
  width: ${theme.proportion * 25 * theme.mobileResizeFactor}px;
  margin: 0 ${theme.proportion * 20 * theme.mobileResizeFactor}px 0
    ${-theme.proportion * 40 * theme.mobileResizeFactor}px;

  @media only screen and (max-width: 640px) {
    margin-right: 0;
  }
`;

const SearchContainer = (props) => {
  const [value, setValue] = useState("");
  const { name, label, placeholder, description, handler } = props.conf;

  const handleSubmit = (e) => {
    e.preventDefault();
    handler(value);
    setValue("");
  };

  return (
    <SearchForm onSubmit={handleSubmit}>
      {window.innerWidth > 640 && (
        <LabelGeneral htmlFor={name} xmarginLeft="25" xfontSize="16">
          {label}
        </LabelGeneral>
      )}
      <SearchInput
        placeholder={placeholder}
        type="text"
        name={name}
        id={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <SearchIcon type="image" src={searchIcon} alt="Submit" />
      {window.innerWidth > 640 && (
        <PGeneral xmarginLeft="20" xfontSize="12" xwidth="180">
          {description}
        </PGeneral>
      )}
    </SearchForm>
  );
};

export default SearchContainer;
