import { combineReducers } from "redux";
import menus from "./menus-reducer";
import company from "./company-reduce";

export default function createReducer(asyncReducers) {
  return combineReducers({
    menus,
    company,
    ...asyncReducers,
  });
}
