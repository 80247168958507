const prdDetials = {
  title: "Product Details",
  nameSpace: "prdcs",
  endPoint: "/productcategories/customerproducts",
  filterKey: "productUuid",
  listKey: "products",
  matchKey: "searchField",
  thumbListKeys: "imageUrl|extImageUrl",
  initHeroImgKey: "imageUrl",
  labelKey: "price1",
  labelBulkKey: "bulkQty",
  labelBulkValueKey: "price2",
  labelSuffix: "per/",
  labelSuffixKey: "unitSize",
  decorLabel: true,
};

export default prdDetials;
