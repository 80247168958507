const returnOrderConf = {
  title: "Your Return Orders",
  nameSpace: "yro",
  endPoint: "/returnorders",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&archivedFlag=0",
  topBtns: [
    {
      text: "Create New Return",
      type: "link",
      path: "/returns/createnew",
      prompt: "Need to request a return?",
    },
  ],
  tableCfg: {
    mainKey: "returnOrderUuid",
    noDataInfo: "You currently have no active return orders.",
    columns: [
      { title: "Invoice No.", key: "invoiceNum", prefix: "#" },
      { title: "Return No.", key: "returnOrderName" },
      {
        title: "Status",
        type: "returnStatus",
        key: "status",
        textAlign: "right",
      },
      {
        title: " ",
        type: "edit",
        subType: "RO",
        limitKey: "status",
        allowed: [0],
        repAllowed: [0, 1],
      },
      {
        title: " ",
        type: "delete",
        limitKey: "status",
        allowed: [0],
        repAllowed: [0, 1],
        furtherLimitUser: ["Joanne B2B"],
        delName: "return order",
      },
      {
        title: " ",
        type: "doc",
        limitKey: "status",
        allowed: [1, 2, 3, 4, 10],
        urlKey: "deliveryDocketUrl",
      },
    ],
  },
  maxPage: 10,
  pageSize: 12,
  pageSizeOpt: true,
};

export default returnOrderConf;
