import React, { useEffect } from "react";
import { connect } from "react-redux";
import TitleCard from "./title-card";
import store, {
  addAsynSimpleListReducer,
  addPagingControlReducer,
} from "../../store";
import getSimpleList from "../../api/simple-list-api";
import {
  FullWidthContainer,
  // theme,
  SearchTitle,
  // H6Title,
  // SpanLGeneral,
} from "../../configurations/general-conf";
import styled from "styled-components";
import ThumbListView from "../views/thumb-list-view";
import DataTablePGN from "../views/data-table-pgn";
// import { NavLink } from "react-router-dom";

// const PListWithFilter = styled(FullWidthContainer)`
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-gap: ${theme.proportion * 20}px;

//   & > *:first-child {
//     grid-column: 1 / span 3;
//   }

//   @media only screen and (max-width: 640px) {
//     grid-template-columns: repeat(2, 1fr);
//   }
// `;

const PListSearch = styled(FullWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

// const SearchHKey = styled(SpanLGeneral)`
//   display: block;
//   font-size: ${theme.proportion * 16}px;
//   line-height: ${theme.proportion * 24}px;
//   padding-left: ${theme.proportion * 16}px;
//   color: ${theme.charcoal};
//   text-decoration: underline;
// `;

const ProductList = (props) => {
  const toPage = (pageIndex) => {
    // console.log(pageIndex);
    const { nameSpace } = props.conf;
    const { uuid, keyword } = props.match.params;
    const limiter = uuid || keyword;
    store.dispatch({
      type: "get_page_" + nameSpace + limiter,
      toPage: pageIndex,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const changePageSize = (pageSize) => {
    // console.log(pageSize);
    const { nameSpace } = props.conf;
    const { uuid, keyword } = props.match.params;
    const limiter = uuid || keyword;
    store.dispatch({
      type: "change_size" + nameSpace + limiter,
      pageSize,
    });
  };

  const toProduct = (uuid) => {
    // console.log(props.dyPath);
    const to = props.dyPath + "/product/" + uuid;
    props.history.push(to);
  };

  useEffect(() => {
    const { nameSpace, endPoint } = props.conf;
    nameSpace &&
      !store.getState()[nameSpace] &&
      addAsynSimpleListReducer(store, props.conf);
    nameSpace && endPoint && getSimpleList(nameSpace, props.dyPath);

    const { uuid, keyword } = props.match.params;
    const limiter = uuid || keyword;
    nameSpace &&
      limiter &&
      !store.getState()[nameSpace + limiter] &&
      addPagingControlReducer(store, props.conf, limiter);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [props.conf, props.match.params, props.dyPath]);

  // console.log(props);

  const { title, listCfg, pageSizeOpt, pageSize: initPageSize } = props.conf;
  // const { list, pageSize, currentPage, dyPath, cmFilters } = props;
  const { list, pageSize, currentPage, dyPath } = props;
  const totalRecords = list?.length;

  const listToShow =
    props.list?.length > 0
      ? list.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      : [];

  // props.list?.length > 0 && console.log(props);
  // listToShow.length > 0 && console.log(listToShow);

  // console.log(cmFilters);

  return (
    <TitleCard
      title={
        dyPath === "/checklevel"
          ? "Check Stock Levels"
          : dyPath === "/promotioncodes/createnew"
          ? "Select Collection (up to 2)"
          : title
      }
      contentPadding="25"
    >
      {props.match?.params?.uuid && (
        <PListSearch>
          <ThumbListView
            {...props}
            listCfg={listCfg}
            list={listToShow}
            toProduct={toProduct}
          />
          {/* {window.innerWidth > 640 && (
            <div>
              <H6Title xmarginBottom="20" xfontSize="12">
                Search History
              </H6Title>
              {cmFilters.map((item, i) => {
                return (
                  <NavLink key={i} to={dyPath + "/search/" + item}>
                    <SearchHKey>{item}</SearchHKey>
                  </NavLink>
                );
              })}
            </div>
          )} */}
        </PListSearch>
      )}
      {props.match?.params?.keyword && (
        <PListSearch>
          <SearchTitle>
            Search results for: <span>{props.match.params.keyword}</span>
          </SearchTitle>
          <ThumbListView
            {...props}
            listCfg={listCfg}
            list={listToShow}
            toProduct={toProduct}
          />
        </PListSearch>
      )}
      {(totalRecords / pageSize > 1 || pageSize !== initPageSize) && (
        <DataTablePGN
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
          pageSizeOpt={pageSizeOpt}
          initPageSize={initPageSize}
          toPage={toPage}
          changePageSize={changePageSize}
        />
      )}
    </TitleCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { nameSpace, filterKey, listKey, searchKey } = ownProps.conf;
  const { uuid, keyword } = ownProps.match.params;
  const limiter = uuid || keyword;
  let list = [];
  // let cmFilters = [];
  // const localCF = JSON.parse(window.localStorage.getItem("cmFilters"));
  // cmFilters = Array.isArray(localCF) ? localCF : cmFilters;
  // console.log(state[nameSpace]);
  if (uuid && state[nameSpace]?.list?.length > 0) {
    const catObj = state[nameSpace].list.find((obj) => obj[filterKey] === uuid);
    list = catObj?.[listKey];
    // console.log(catObj);
    // if (catObj?.productCategoryName.indexOf(catObj?.displayName) < 0) {
    //   list.map((item) => {
    //     return (item.productCategoryName = catObj?.productCategoryName);
    //   });
    // }
  }
  if (keyword && state[nameSpace]?.list?.length > 0) {
    state[nameSpace].list.map((listItem) => {
      const foundObj = listItem[listKey].filter((obj) => {
        const kwl = keyword.toLowerCase();
        let dataToSearch = "";
        searchKey.split("|").map((key) => {
          return (dataToSearch += obj[key] ? obj[key].toLowerCase() : "");
        });
        return dataToSearch.indexOf(kwl) > -1;
      });
      return (list = [...list, ...foundObj]);
    });

    // if (list.length > 0) {
    //   !cmFilters.find((obj) => obj === keyword) && cmFilters.unshift(keyword);
    //   cmFilters.length > 18 && cmFilters.pop();
    // }
    // // console.log(cmFilters);
    // window.localStorage.setItem("cmFilters", JSON.stringify(cmFilters));
  }
  return {
    list,
    pageSize: state[nameSpace + limiter]?.pageSize,
    currentPage: state[nameSpace + limiter]?.currentPage,
    // cmFilters,
  };
};

export default connect(mapStateToProps)(ProductList);
