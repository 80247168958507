import React from "react";
import {
  FullWidthContainer,
  PGeneral,
  theme,
  SpanBGeneral,
} from "../../configurations/general-conf";
import styled from "styled-components";

const InfoContainer = styled(FullWidthContainer)`
  padding: ${theme.proportion * 15}px ${theme.proportion * 25}px;
`;
const InfoP = styled(PGeneral)`
  font-size: ${theme.proportion * 14}px;
  line-height: ${theme.proportion * 22}px;
`;

const InfoPrjOrders = () => {
  return (
    <InfoContainer xbackgroundColor="biege">
      <InfoP>
        If there are any project orders required, please contact your Sales Rep,
        or our Customer Service Team.
      </InfoP>
      <InfoP>
        Call <SpanBGeneral>1300 901 355</SpanBGeneral> or email
        <SpanBGeneral> sales@asttimbers.com.au</SpanBGeneral> for more
        information.
      </InfoP>
    </InfoContainer>
  );
};

export default InfoPrjOrders;
