import React from "react";
import {
  FullWidthContainer,
  FullWHContainer,
  H3Title,
  theme,
  ButtonFuncG,
  SpanMGeneral,
} from "../../configurations/general-conf";
import styled from "styled-components";

const CardContainer = styled(FullWHContainer)`
  border: 1px solid ${theme.biege};
  display: flex;
  flex-direction: column;
`;

const CardTitleContainer = styled(FullWidthContainer)`
  background-color: ${theme.biege};
  padding-left: ${theme.proportion * 25}px;
  padding-right: ${theme.proportion * 18}px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const SlimButtonFuncG = styled(ButtonFuncG)`
  padding: ${theme.proportion * 7}px;
`;

const CardContent = styled(FullWidthContainer)`
  display: flex;
  flex-direction: ${(props) =>
    props.contentLayout ? props.contentLayout : "column"};
  justify-content: space-between;
  height: 100%;
  align-items: ${(props) =>
    props.contentAlign ? props.contentAlign : "center"};
`;

const TitleCard = (props) => {
  return (
    <CardContainer>
      <CardTitleContainer>
        <H3Title xfontSize="16" xlineHeight="47">
          {props.title}
        </H3Title>
        {props.topBtns?.length > 0 && (
          <div>
            {props.topBtns?.map((btn, i) => {
              switch (btn.type) {
                case "link":
                  return (
                    <div key={`sec_${i}`}>
                      {btn.prompt && (
                        <SpanMGeneral
                          key={`pmpt_${i}`}
                          xfontSize="14"
                          xmarginRight="18"
                        >
                          {btn.prompt}
                        </SpanMGeneral>
                      )}
                      <SlimButtonFuncG
                        key={i}
                        onClick={(e) => {
                          props.toPath(e, btn.path);
                        }}
                      >
                        {btn.text}
                      </SlimButtonFuncG>
                    </div>
                  );
                default:
                  return <SlimButtonFuncG key={i}>{btn.text}</SlimButtonFuncG>;
              }
            })}
          </div>
        )}
      </CardTitleContainer>
      <CardContent
        xpadding={props.contentPadding}
        contentLayout={props.contentLayout}
        contentAlign={props.contentAlign}
      >
        {props.children}
      </CardContent>
    </CardContainer>
  );
};

export default TitleCard;
