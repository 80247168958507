const activeOrdersConf = {
  title: "Current Orders",
  nameSpace: "aodt",
  endPoint: "/saleorders",
  // delItemName: "draft",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query: "&deliveryFlag=0,1,2,5,6&status=0,1,2,3,4,5,6,7,8,9,10,11,12",
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "You currently have no active orders.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No. / Order Name", key: "info2" },
      {
        title: "Dispatch Date",
        key: "edd",
        type: "localTime",
        format: "DD/MM/yyyy",
      },
      {
        title: "Status",
        type: "orderStatus",
        key: "status",
        subKey: "deliveryFlag",
        textAlign: "right",
      },
      {
        title: " ",
        type: "edit",
        limitKey: "status",
        allowed: [0, 2, 9, 10, 12],
        repAllowed: [0, 1, 2, 9, 10, 11, 12],
        furtherTestValue: 1,
        furtherLimitKey: "deliveryFlag",
        furtherAllowed: [1,2,3,5,6],
        // path: "/orderonline/draftorder/",
      },
      {
        title: " ",
        type: "delete",
        limitKey: "status",
        allowed: [0, 2, 9, 10, 12],
      },
      {
        title: " ",
        type: "doc",
        limitKey: "status",
        allowed: [1, 2, 10, 11, 12],
        limitKey2: "saleOrderName",
        urlKey: "orderSummaryUrl",
      },
    ],
  },
  maxPage: 5,
};

export default activeOrdersConf;
