import React from "react";
import { PGeneral, H2Title } from "../../configurations/general-conf";
import TitleCard from "../widgets/title-card";
import LoginContainer from "../containers/login-container";
import { NavLink } from "react-router-dom";
import SimpleConfirmation from "../widgets/simple-confirmation";
import { confirmAlert } from "react-confirm-alert";

const LoginLayout = (props) => {
  const fpass = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <SimpleConfirmation
            title="Forgot Password"
            message="Please contact our support team support@asttimbers.com.au for any B2B login related enquires."
            onClose={onClose}
          />
        );
      },
    });
  };
  return (
    <TitleCard title="Retailer Login" contentPadding="40">
      <PGeneral xfontSize="14" xlineHeight="20">
        Welcome to the AST Retailer Portal. This site is for our retail
        customers only. If you are a retailer and would like to request for an
        account with us please go to the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.asttimbers.com.au/contact-us/"
        >
          request account
        </a>{" "}
        form.
      </PGeneral>
      <PGeneral xmarginTop="35" xfontSize="14" xlineHeight="20">
        Please note: This site is only accessible for our existing retail
        customers only.
      </PGeneral>
      <H2Title xfontSize="30" xmarginTop="60" xtextAlign="center">
        Welcome
      </H2Title>
      <LoginContainer {...props} />
      <PGeneral
        xmarginTop="35"
        xfontSize="14"
        xlineHeight="20"
        xtextAlign="center"
      >
        <NavLink to="#" onClick={(e) => fpass(e)}>
          Forgot Password
        </NavLink>
      </PGeneral>
      <PGeneral
        xmarginTop="10"
        xfontSize="14"
        xlineHeight="20"
        xtextAlign="center"
      >
        No account?{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.asttimbers.com.au/contact-us/"
        >
          Request Account Form**
        </a>{" "}
      </PGeneral>
      <PGeneral
        xmarginTop="10"
        xcolor="darkerBiege"
        xfontSize="10"
        xtextAlign="center"
      >
        **Only applicable for retailers only
      </PGeneral>
    </TitleCard>
  );
};

export default LoginLayout;
