import React from "react";
import store from "../../store";
import { getWhname } from "./utilities";
import styled from "styled-components";
import { theme } from "../../configurations/general-conf";

const StockInfoContainer = styled.ul`
  display: flex;
`;

const StockInfoItem = styled.li`
  margin-right: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  color: ${(props) => (props.lowStock ? theme.noticeRed : theme.passGreen)};
`;

const CurrentSampleStockVA = (props) => {
  const { data } = props;
  const stock = data.smapleInfo?.products[0]?.currentStock || 0;
  return (
    <StockInfoContainer>
      {stock &&
        stock.map((item, i) => {
          let stockSet = item;
          data.smapleInfo?.products.map((product) => {
            stockSet =
              product.currentStock[i] < stockSet
                ? product.currentStock[i]
                : stockSet;
            return null;
          });
          // console.log(stockSet < data.quantity);
          return (
            getWhname(store.getState()["company"]["info" + (i + 1)]) && (
              <StockInfoItem key={i} lowStock={stockSet < data.quantity}>
                <span>
                  {getWhname(store.getState()["company"]["info" + (i + 1)])}{" "}
                </span>
                ({stockSet})
              </StockInfoItem>
            )
          );
        })}
    </StockInfoContainer>
  );
};

export default CurrentSampleStockVA;
