import React from "react";
import store from "../../store";
import {
  SpanBGeneral,
  SpanLGeneral,
  PGeneral,
} from "../../configurations/general-conf";
import { getWhname, getStockTD } from "./utilities";

const CurrentStockSCache = (props) => {
  const { data } = props;
  const stock = data.currentStock;
  return (
    <>
      {stock &&
        stock.map((item, i) => {
          return (
            getWhname(store.getState()["company"]["info" + (i + 1)]) && <PGeneral key={i} xlineHeight="20">
              <SpanBGeneral xfontSize="14">
                {getWhname(store.getState()["company"]["info" + (i + 1)])}{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14">
                {getStockTD(
                  item,
                  data.unitSize,
                  data.unitSymbol,
                  data.unitName
                )}{" "}
                available
              </SpanLGeneral>
            </PGeneral>
          );
        })}
    </>
  );
};

export default CurrentStockSCache;
