import React from "react";
import store from "../../store";
import { getWhname, getStockTD } from "./utilities";

const CurrentStockCache = (props) => {
  const { data } = props;
  const stock = data.currentStock;
  return (
    <>
      {stock &&
        stock.map((item, i) => {
          return (
            getWhname(store.getState()["company"]["info" + (i + 1)]) && <div key={i}>
              <span>
                {getWhname(store.getState()["company"]["info" + (i + 1)])}{" "}
              </span>
              {getStockTD(
                item,
                data.unitSize,
                data.unitSymbol,
                data.unitName
              )}{" "}
              available
            </div>
          );
        })}
    </>
  );
};

export default CurrentStockCache;
