import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FullWHContainer } from "../../configurations/general-conf";
import bannersConf from "../../configurations/data-tables/banners";
import store, { addAsynSimpleListReducer } from "../../store";
import getSimpleList from "../../api/simple-list-api";
import { fileurl, FullWHImg } from "../../configurations/general-conf";

const TopAdLayout = (props) => {
  useEffect(() => {
    const { nameSpace } = bannersConf;
    nameSpace &&
      !store.getState()[nameSpace] &&
      addAsynSimpleListReducer(store, bannersConf);
    !store.getState()[nameSpace]?.list && getSimpleList(nameSpace);
  }, []);

  // console.log(props.data);

  return (
    <FullWHContainer xbackgroundColor="biege">
      {props.data && !props.data.info1 && (
        <FullWHImg src={fileurl + props.data.imgUrl} />
      )}
      {props.data && props.data.info1 && (
        <a target="_blank" rel="noreferrer" href={props.data.info1}>
          <FullWHImg src={fileurl + props.data.imgUrl} />
        </a>
      )}
    </FullWHContainer>
  );
};

const mapStateToProps = (state) => {
  const { nameSpace } = bannersConf;
  let banner = null;
  if (state[nameSpace]?.list?.length > 0) {
    banner = state[nameSpace].list.find((obj) => obj.bannerName === "Top");
  }
  return {
    data: banner,
  };
};

export default connect(mapStateToProps)(TopAdLayout);
