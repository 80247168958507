const prdBySearch = {
  title: "Product by Search",
  nameSpace: "prdcs",
  endPoint: "/productcategories/customerproducts",
  listKey: "products",
  searchKey: "productName|productCategoryName|productDimension|notes|info3",
  pageSize: 12,
  pageSizeOpt: true,
  listCfg: {
    mainKey: "productUuid",
    lifeStyleKey: "extImageUrl",
    noDataInfo: "No product available.",
    itemBorder: true,
    rowGap: 15,
    columnGap: 30,
    colCount: 4,
    columns: [
      {
        type: "swatch",
        coverKey: "imageUrl",
        labelKey: "price1",
        factorKey: "unitSize",
        labelSuffix: "per/",
        labelSuffixKey: "unitSymbol",
        labelSuffixKeyAlt: "unitName",
        decorLabel: true,
      },
      {
        type: "title",
        key: "productName",
        altKey: "productDisplayName",
        altKey2: "productColor",
      },
      {
        type: "sub_title",
        key: "productCategoryName",
      },
      {
        type: "description",
        key: "productDimension",
        prefix: "Size",
        decorInter: true,
      },
    ],
  },
};

export default prdBySearch;
