const smpByCat = {
  title: "Sample Combinations by Category",
  nameSpace: "prdsmpc",
  endPoint: "/productcategories/samplecombinations",
  filterKey: "productCategoryUuid",
  filterEndPoint: "/productcategories/parents",
  listKey: "sampleCombinations",
  pageSize: 48,
  pageSizeOpt: true,
  listCfg: {
    mainKey: "sampleCombinationUuid",
    lifeStyleKey: "extImageUrl",
    noDataInfo: "No sample combinations available.",
    itemBorder: true,
    rowGap: 15,
    columnGap: 30,
    colCount: 4,
    columns: [
      {
        type: "swatch",
        coverKey: "imageUrl",
      },
      {
        type: "title",
        key: "combinationName",
        marginBottom: 5,
      },
    ],
  },
};

export default smpByCat;
