const mainPrdCatsConf = {
  title: "Order Online",
  nameSpace: "prdcs",
  endPoint: "/productcategories/customerproducts",
  listCfg: {
    mainKey: "productCategoryUuid",
    noDataInfo: "No product category available.",
    columns: [
      { key: "cover1", type: "cat_cover", altKey: "productCategoryName" },
    ],
  },
};

export default mainPrdCatsConf;
