import React from "react";
import { SpanMGeneral } from "../../configurations/general-conf";

const PriceTag = (props) => {
  const {
    unitSymbol,
    unitNanme,
    unitSize,
    perText,
    price,
    priceBulk,
    bulkLimiter,
    currentQty,
    discountAmount,
  } = props;
  const priceToCal =
    !discountAmount && bulkLimiter && currentQty && currentQty >= bulkLimiter
      ? priceBulk
      : price;
  const priceTD =
    unitSymbol === "m2" || unitSymbol === "m²"
      ? (priceToCal / unitSize).toFixed(2)
      : priceToCal?.toFixed(2);
  const suffixTD =
    unitSymbol === "m2" || unitSymbol === "m²" ? unitSymbol : unitNanme;
  // console.log(discountAmount);
  return (
    <div>
      {discountAmount && (
        <SpanMGeneral xcolor="noticeRed" xmarginRight="5">
          <strike>${priceTD}</strike>
        </SpanMGeneral>
      )}
      ${(priceTD - (discountAmount || 0)).toFixed(2)}{" "}
      <span>
        {perText || "/ "}
        {suffixTD || "unit"}
      </span>
    </div>
  );
};

export default PriceTag;
