import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  CCContainer,
  H3Title,
  theme,
  assembleStyles,
  fileurl,
  FullWHImg,
} from "../../configurations/general-conf";
import bannersConf from "../../configurations/data-tables/banners";
import store, { addAsynSimpleListReducer } from "../../store";
import getSimpleList from "../../api/simple-list-api";

const FBGridLayout = styled(CCContainer)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.proportion * 20}px;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const TitleWrapper = styled(H3Title)`
  grid-column: 1 / span 2;
`;

const FooterBannerContainer = styled.div`
  ${(props) => assembleStyles(props, theme)};
`;

const FooterLayout = (props) => {
  useEffect(() => {
    const { nameSpace } = bannersConf;
    nameSpace &&
      !store.getState()[nameSpace] &&
      addAsynSimpleListReducer(store, bannersConf);
    store.getState()[nameSpace]?.list?.length === 0 && getSimpleList(nameSpace);
  }, []);

  return (
    <FBGridLayout>
      <TitleWrapper xfontSize="16">What’s NEW and Special OFFERS</TitleWrapper>
      <FooterBannerContainer xheight="250" xbackgroundColor="biege">
        {props.bannerLeft && !props.bannerLeft.info1 && (
          <FullWHImg src={fileurl + props.bannerLeft.imgUrl} />
        )}
        {props.bannerLeft && props.bannerLeft.info1 && (
          <a target="_blank" rel="noreferrer" href={props.bannerLeft.info1}>
            <FullWHImg src={fileurl + props.bannerLeft.imgUrl} />
          </a>
        )}
      </FooterBannerContainer>
      <FooterBannerContainer xheight="250" xbackgroundColor="biege">
        {props.bannerRight && !props.bannerRight.info1 && (
          <FullWHImg src={fileurl + props.bannerRight.imgUrl} />
        )}
        {props.bannerRight && props.bannerRight.info1 && (
          <a target="_blank" rel="noreferrer" href={props.bannerRight.info1}>
            <FullWHImg src={fileurl + props.bannerRight.imgUrl} />
          </a>
        )}
      </FooterBannerContainer>
    </FBGridLayout>
  );
};

const mapStateToProps = (state) => {
  const { nameSpace } = bannersConf;
  let bannerLeft = null;
  let bannerRight = null;
  if (state[nameSpace]?.list?.length > 0) {
    bannerLeft = state[nameSpace].list.find(
      (obj) => obj.bannerName === "Bottom_Left"
    );
    bannerRight = state[nameSpace].list.find(
      (obj) => obj.bannerName === "Bottom_Right"
    );
  }
  return {
    bannerLeft,
    bannerRight,
  };
};

export default connect(mapStateToProps)(FooterLayout);
