import React from "react";
import styled from "styled-components";
import {
  FullWidthContainer,
  CCContainer,
  theme,
  ExlinkIcon,
} from "../../configurations/general-conf";
import ASTLogo from "../../resources/ast_logo.svg";
import logoShadow from "../../resources/logo_shadow.svg";
import exLinkIcon from "../../resources/icons/ex_link.svg";

const HeaderFlexLayout = styled(CCContainer)`
  display: flex;
  align-items: flex-start;
`;

const ASTLogoBlock = styled.img`
  width: ${theme.proportion * 182 * theme.mobileResizeFactor}px;
`;

const LogoShadow = styled.img`
  width: ${theme.proportion * 20}px;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const ASTExLinksList = styled.ul`
  padding-top: ${theme.proportion * 4}px;
  margin-left: auto;
  display: flex;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const ASTExLinksListItem = styled.li`
  padding-left: ${theme.proportion * 30}px;
  padding-right: ${theme.proportion * 30}px;
  border-right: 1px solid ${theme.bright};
  font-size: ${theme.proportion * 14}px;
  line-height: ${theme.proportion * 32}px;
  color: ${theme.bright};
  &:last-child {
    padding-right: 0;
    border-right: none;
  }
  & a {
    color: ${theme.bright};
    font-family: ${theme.boldFont};
  }
  & a:hover {
    color: ${theme.biege};
  }
`;

const HeaderExContainer = styled(FullWidthContainer)`
  @media only screen and (max-width: 640px) {
    background-color: transparent;
  }
`;

const HeaderExlinksView = () => {
  return (
    <HeaderExContainer xheight="40" xbackgroundColor="astGreen">
      <HeaderFlexLayout>
        <ASTLogoBlock src={ASTLogo} />
        <LogoShadow src={logoShadow} />
        <ASTExLinksList>
          <ASTExLinksListItem>
            <ExlinkIcon src={exLinkIcon} />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.asttimbers.com.au/"
            >
              www.asttimbers.com.au
            </a>
          </ASTExLinksListItem>
          <ASTExLinksListItem>
            <ExlinkIcon src={exLinkIcon} />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.grandoakflooring.com.au/"
            >
              www.grandoakflooring.com.au
            </a>
          </ASTExLinksListItem>
          <ASTExLinksListItem>
            <ExlinkIcon src={exLinkIcon} />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hickoryfloors.com.au/"
            >
              www.hickoryfloors.com.au
            </a>
          </ASTExLinksListItem>
        </ASTExLinksList>
      </HeaderFlexLayout>
    </HeaderExContainer>
  );
};

export default HeaderExlinksView;
