import moment from "moment";

const trackOrdersConf = {
  title: "Track Orders",
  nameSpace: "todt",
  endPoint: "/saleorders",
  endPointLimiter: {
    limiterName: "customerUuid",
    nameSpace: "company",
    key: "customerUuid",
  },
  query:
    "&deliveryFlag=3&customerDeliveryType=0,1&qryFrom=" +
    moment().add(-30, "days").format("YYYY-MM-DD"),
  loadingDelay: 1000,
  tableCfg: {
    mainKey: "saleOrderUuid",
    noDataInfo: "There is no order to track yet.",
    columns: [
      { title: "Order No.", key: "saleOrderName", prefix: "#" },
      { title: "PO No.", key: "info2" },
      {
        title: "Tracking",
        type: "tracking",
        key: "freightTrackingLink",
        subKey: "fc",
        textAlign: "right",
      },
    ],
  },
  maxPage: 5,
};

export default trackOrdersConf;
